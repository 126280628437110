export class Global{

    getNumber (num?:number) {
        return Math.abs(Number(num)) >= 1.0e+9
        ? (Math.abs(Number(num)) / 1.0e+9).toFixed(2) + "B"
        : Math.abs(Number(num)) >= 1.0e+6
        ? (Math.abs(Number(num)) / 1.0e+6).toFixed(2) + "M"
        : Math.abs(Number(num)) >= 1.0e+3
        ? (Math.abs(Number(num)) / 1.0e+3).toFixed(2) + "K"
        : Math.abs(Number(num));
    }

    getDateMonth(timestampString:string){
        const date: Date = new Date(timestampString);
        const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long' };
        const formattedDate: string = date.toLocaleDateString('en-IN', options);
        return formattedDate
    }

    getDateMonthYear(timestampString:string){
        const date: Date = new Date(timestampString);
        const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year:'numeric' };
        const formattedDate: string = date.toLocaleDateString('en-IN', options);
        return formattedDate
    }

    getDaysDifference(timestampString:string){
        const currentDate = new Date();
        const targetDate = new Date(timestampString);
        // const timeDifference = targetDate.getTime() - currentDate.getTime();
        // const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const daysDifference = targetDate.getDay() - currentDate.getDay();
        return daysDifference
    }

    getDaysAgoTime(timestampString:any) {
      const currentDate:any = new Date();
      const targetDate:any = new Date(timestampString)
      const dateDifference = currentDate - targetDate;
      const daysAgo = Math.floor(dateDifference / (1000 * 60 * 60 * 24));
      if (daysAgo === 0) {
        const hoursAgo = Math.floor(dateDifference / (1000 * 60 * 60));
        if (hoursAgo === 0) {
          const minutesAgo = Math.floor(dateDifference / (1000 * 60));
          return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
        }
        return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
      }
      return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
    }

    getVideoDuration(duration:any){
      const videoDuration = duration<60?`00:${duration}`:duration==60?`01:00`:duration
      return videoDuration;
    }

    isChannelFav(favChannelIds:number[],channelId:number){
      if(favChannelIds && channelId){
        if (favChannelIds.includes(channelId)) {
          return true
        } else {
          return false
        }
      }
    }


}