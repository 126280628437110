import React from 'react'
import Icons from './Icons'
import { useNavigate } from 'react-router-dom';


const Back:React.FC <any> = ({title}:{title:string}) => {
  const {ArrowLeftIcon} = Icons()
  const navigate = useNavigate()

  const handleClick = () => navigate(-1);
  return (
    <>
    <div className='row pt-2'>
        <div onClick={handleClick} className='d-flex'>
            <div><ArrowLeftIcon/></div>
            <div className='ms-4' style={{fontWeight:600}}>{title}</div>
        </div>
        <hr className='m-0 mt-2'/>  
    </div>
    </>
  )
}

export default Back
