import {createAsyncThunk} from '@reduxjs/toolkit'
import { User } from '../../api/user'
const userModel = new User()


export const userDetails = createAsyncThunk('userDetails', async(token:any,{ rejectWithValue })=>{
    try {
        const userDetailsRes:any = await userModel.userDetails(token)
        if(userDetailsRes.data.status==='success'){
            return userDetailsRes.data.data
        }
    } catch (error:any) {
        if(error.errors){
            return rejectWithValue({isValidationError:true, validationMessage:error.errors[0] || 'An error occurred during validation'});
        }
        return rejectWithValue(error.message)
    }
})
