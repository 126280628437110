import React from 'react'

const Icons = () => {

    const PlusIcon = ({color}:{color:any}) => {
        return ( 
            <svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 448 512">
            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" fill={color}/></svg>
        );
    }

    const DashboardIcon = ({color}:{color:any}) => {
        return ( 
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 20 20" fill="none" >
            <path  d="M1.11111 11.1111H7.77778C8.38889 11.1111 8.88889 10.6111 8.88889 10V1.11111C8.88889 0.5 8.38889 0 7.77778 0H1.11111C0.5 0 0 0.5 0 1.11111V10C0 10.6111 0.5 11.1111 1.11111 11.1111ZM1.11111 20H7.77778C8.38889 20 8.88889 19.5 8.88889 18.8889V14.4444C8.88889 13.8333 8.38889 13.3333 7.77778 13.3333H1.11111C0.5 13.3333 0 13.8333 0 14.4444V18.8889C0 19.5 0.5 20 1.11111 20ZM12.2222 20H18.8889C19.5 20 20 19.5 20 18.8889V10C20 9.38889 19.5 8.88889 18.8889 8.88889H12.2222C11.6111 8.88889 11.1111 9.38889 11.1111 10V18.8889C11.1111 19.5 11.6111 20 12.2222 20ZM11.1111 1.11111V5.55556C11.1111 6.16667 11.6111 6.66667 12.2222 6.66667H18.8889C19.5 6.66667 20 6.16667 20 5.55556V1.11111C20 0.5 19.5 0 18.8889 0H12.2222C11.6111 0 11.1111 0.5 11.1111 1.11111Z" fill={color}
            // fill-opacity="0.5"
            />
            </svg>

        );
    }
    const VideoIcon = ({color}:{color:any}) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" 
            height="24" 
            width="24"
            viewBox="0 -960 960 960" 
            >
            <path d="m460-380 280-180-280-180v360ZM320-240q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320Zm0-80h480v-480H320v480ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm160-720v480-480Z"
            fill={color}
            // fill-opacity="0.5"
            />
            </svg>
        );
    }
    const ChannelIcon = ({color}:{color:any}) => {
        return (
            <svg 
            xmlns="http://www.w3.org/2000/svg" 
            height="24" viewBox="0 -960 960 960" 
            width="24">
            <path d="m380-340 280-180-280-180v360Zm-60 220v-80H160q-33 0-56.5-23.5T80-280v-480q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v480q0 33-23.5 56.5T800-200H640v80H320ZM160-280h640v-480H160v480Zm0 0v-480 480Z"
            fill={color}
            // fill-opacity="0.5"
            />
            </svg>
        );
    }
    const CommunityIcon = ({color}:{color:any}) => {
        return (
            <svg 
            width="24" 
            height="24" 
            viewBox="0 0 22 22" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            >
            <path 
            d="M11.561 8.789C11.561 6.974 10.076 5.489 8.261 5.489C6.446 5.489 4.961 6.974 4.961 8.789C4.961 10.604 6.446 12.089 8.261 12.089C10.076 12.089 11.561 10.604 11.561 8.789ZM8.261 9.889C7.656 9.889 7.161 9.394 7.161 8.789C7.161 8.184 7.656 7.689 8.261 7.689C8.866 7.689 9.361 8.184 9.361 8.789C9.361 9.394 8.866 9.889 8.261 9.889ZM15.411 12.089C16.632 12.089 17.611 11.11 17.611 9.889C17.611 8.668 16.632 7.689 15.411 7.689C14.19 7.689 13.2 8.668 13.211 9.889C13.211 11.11 14.19 12.089 15.411 12.089ZM11 0C4.928 0 0 4.928 0 11C0 17.072 4.928 22 11 22C17.072 22 22 17.072 22 11C22 4.928 17.072 0 11 0ZM4.235 16.621C4.983 16.027 6.732 15.4 8.261 15.4C8.338 15.4 8.426 15.411 8.514 15.411C8.778 14.707 9.251 13.992 9.944 13.365C9.328 13.255 8.745 13.189 8.261 13.189C6.831 13.189 4.532 13.684 3.058 14.762C2.508 13.618 2.2 12.342 2.2 10.989C2.2 6.138 6.149 2.189 11 2.189C15.851 2.189 19.8 6.138 19.8 10.989C19.8 12.309 19.503 13.563 18.975 14.696C17.875 14.047 16.379 13.739 15.411 13.739C13.739 13.739 10.461 14.63 10.461 16.709V19.767C7.964 19.624 5.742 18.436 4.235 16.621Z" 
            fill={color}
            // fill-opacity="0.5"
            />
            </svg>


        );
    }
    const MoreIcon = ({color}:{color:any}) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" 
            height="24" 
            viewBox="0 -960 960 960"
            width="24">
            <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z"
            fill={color}
            // fill-opacity="0.5"
            />
            </svg>
        );
    }
    const LikeIcon = ({color}:{color:any}) => {
        return (
            <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path 
                id="Vector" 
                d="M17.1558 1.09402C15.1222 -0.573804 12.0979 -0.273809 10.2313 1.57973L9.50027 2.30472L8.76923 1.57973C6.90638 -0.273809 3.87831 -0.573804 1.84476 1.09402C-0.485668 3.00827 -0.608127 6.44393 1.47738 8.51889L8.65791 15.6545C9.12177 16.1152 9.87507 16.1152 10.3389 15.6545L17.5195 8.51889C19.6087 6.44393 19.4862 3.00827 17.1558 1.09402Z" 
                fill={color}
                />
            </svg>

        );
    }
    const FavoriteIcon = () => {
        return(
            // <svg 
            // xmlns="http://www.w3.org/2000/svg" 
            // height="22" viewBox="0 -960 960 960"
            // width="19">
            // <path 
            // d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"
            // fill='gray'
            // />
            // </svg>
            <svg xmlns="http://www.w3.org/2000/svg" height="22" width="19" viewBox="0 0 512 512">
            <path d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z" fill='gray'/></svg>
        )
    }
    const FavoriteActiveIcon = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" height="22" width="19" viewBox="0 0 512 512">
            <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" fill='red'/></svg>
        )
    }
    const HelpIcon = ({color}:{color:any}) => {
        return (
            <svg 
            xmlns="http://www.w3.org/2000/svg" 
            height="20" 
            viewBox="0 -960 960 960" 
            width="20">
            <path 
            d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
            fill={color}
            />
            </svg>
        )
    }
    const YouTubeIcon = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" 
            width="20" 
            height="20" 
            fill="currentColor" 
            className="bi bi-youtube" 
            viewBox="0 0 16 16"
            >
            <path 
            d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408z"
            fill='#FF0000'
            />
            </svg>
        )
    }
    const Location = ({color}:{color:string}) => {
        return(
            <svg 
            xmlns="http://www.w3.org/2000/svg" 
            height="20" 
            viewBox="0 -960 960 960" 
            width="20"
            >
            <path 
            d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z"
            fill={color}
            />
            </svg>
        )
    }
    const EqualizerIcon = ({color}:{color:string}) => {
        return(
            <svg 
            xmlns="http://www.w3.org/2000/svg" 
            height="16" 
            viewBox="0 -960 960 960" 
            width="16"
            >
            <g transform="scale(-1, 1) translate(-960, 0)">
              <path d="M160-160v-320h160v320H160Zm240 0v-640h160v640H400Zm240 0v-440h160v440H640Z" 
              fill='gray'
              />
            </g>
          </svg>
        )
    }
    const EqualizerAnalyticsIcon = ({color}:{color:string}) => {
        return(
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 448 512">
        <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm64 192c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm64-64c0-17.7 14.3-32 32-32s32 14.3 32 32V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V160zM320 288c17.7 0 32 14.3 32 32v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V320c0-17.7 14.3-32 32-32z" fill={color}/></svg>
        )
    }
    const ThumbsUpIcon = () => {
        return(
            <svg width="14" 
            height="14" viewBox="0 0 18 18" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path id="Vector" d="M6.54545 18H13.9091C14.5882 18 15.1691 17.55 15.4145 16.902L17.8855 10.557C17.9591 10.35 18 10.134 18 9.9V8.1C18 7.11 17.2636 6.3 16.3636 6.3H11.2009L11.9782 2.187L12.0027 1.899C12.0027 1.53 11.8636 1.188 11.6427 0.945L10.7755 0L5.38364 5.931C5.08909 6.255 4.90909 6.705 4.90909 7.2V16.2C4.90909 17.19 5.64545 18 6.54545 18ZM6.54545 7.2L10.0964 3.294L9 8.1H16.3636V9.9L13.9091 16.2H6.54545V7.2ZM0 7.2H3.27273V18H0V7.2Z" 
            fill="black" 
            fill-opacity="0.5"
            />
            </svg>
        )
    }
    const PlayIcon = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 -960 960 960" width="26"><path d="m380-300 280-180-280-180v360ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" fill='white'/></svg>
        )
    }
    const TrendingUpIcon = () => {
        return(
            <svg 
            xmlns="http://www.w3.org/2000/svg" 
            height="24" 
            viewBox="0 -960 960 960" 
            width="24"
            >
            <path 
            d="m136-240-56-56 296-298 160 160 208-206H640v-80h240v240h-80v-104L536-320 376-480 136-240Z"
            fill='#0080FF'
            />
            </svg>
        )
    }
    const CheckIcon = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
        )
    }
    const PaperPlaneIcon = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512">
            <path d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376V479.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z"/></svg>
        )
    }
    const ListIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
            <path d="M24 56c0-13.3 10.7-24 24-24H80c13.3 0 24 10.7 24 24V176h16c13.3 0 24 10.7 24 24s-10.7 24-24 24H40c-13.3 0-24-10.7-24-24s10.7-24 24-24H56V80H48C34.7 80 24 69.3 24 56zM86.7 341.2c-6.5-7.4-18.3-6.9-24 1.2L51.5 357.9c-7.7 10.8-22.7 13.3-33.5 5.6s-13.3-22.7-5.6-33.5l11.1-15.6c23.7-33.2 72.3-35.6 99.2-4.9c21.3 24.4 20.8 60.9-1.1 84.7L86.8 432H120c13.3 0 24 10.7 24 24s-10.7 24-24 24H32c-9.5 0-18.2-5.6-22-14.4s-2.1-18.9 4.3-25.9l72-78c5.3-5.8 5.4-14.6 .3-20.5zM224 64H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32z"/></svg>
        )
    }
    const MusicIcon = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
            <path d="M499.1 6.3c8.1 6 12.9 15.6 12.9 25.7v72V368c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6V147L192 223.8V432c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6V200 128c0-14.1 9.3-26.6 22.8-30.7l320-96c9.7-2.9 20.2-1.1 28.3 5z"/></svg>
        )
    }
    const IdCardIcon = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
            <path d="M512 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H512zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM208 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm-32 32c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16H304c8.8 0 16-7.2 16-16c0-44.2-35.8-80-80-80H176zM376 144c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H376zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H376z"/></svg>
        )
    }
    const TrophyIcon = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
            <path d="M400 0H176c-26.5 0-48.1 21.8-47.1 48.2c.2 5.3 .4 10.6 .7 15.8H24C10.7 64 0 74.7 0 88c0 92.6 33.5 157 78.5 200.7c44.3 43.1 98.3 64.8 138.1 75.8c23.4 6.5 39.4 26 39.4 45.6c0 20.9-17 37.9-37.9 37.9H192c-17.7 0-32 14.3-32 32s14.3 32 32 32H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H357.9C337 448 320 431 320 410.1c0-19.6 15.9-39.2 39.4-45.6c39.9-11 93.9-32.7 138.2-75.8C542.5 245 576 180.6 576 88c0-13.3-10.7-24-24-24H446.4c.3-5.2 .5-10.4 .7-15.8C448.1 21.8 426.5 0 400 0zM48.9 112h84.4c9.1 90.1 29.2 150.3 51.9 190.6c-24.9-11-50.8-26.5-73.2-48.3c-32-31.1-58-76-63-142.3zM464.1 254.3c-22.4 21.8-48.3 37.3-73.2 48.3c22.7-40.3 42.8-100.5 51.9-190.6h84.4c-5.1 66.3-31.1 111.2-63 142.3z"/></svg>
        )
    }
    const IdBadgeIcon = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" height="18" width="16" viewBox="0 0 384 512">
            <path d="M256 48V64c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H256zM0 64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM160 320h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H96c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z"/></svg>
        )
    }
    const AddressCardIcon = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
            <path d="M512 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H512zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM208 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm-32 32c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16H304c8.8 0 16-7.2 16-16c0-44.2-35.8-80-80-80H176zM376 144c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H376zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24H376z"/></svg>
        )
    }
    const CreditCardIcon = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
            <path d="M512 80c8.8 0 16 7.2 16 16v32H48V96c0-8.8 7.2-16 16-16H512zm16 144V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V224H528zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H248z"/></svg>
        )
    }
    const TrashCanIcon = () => {
        return<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 448 512">
        <path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"/></svg>
    }
    const SignOutIcon = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
            <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z"/></svg>
        )
    }
    const ArrowRightIcon = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512">
            <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
        )
    }
    const CircleIcon = ({imageURL}:{imageURL:string}) => {
        return(
            <img src={imageURL} style={{width:35, height: 35 ,background: 'purple', borderRadius:999, border: '1px rgba(0, 0, 0, 0.15) solid'}} />
            // <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 512 512">
            // <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" fill='blue'/></svg>
        )
    }
    const CheckIconNew = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512">
            <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
        )
    }
    const CrownIcon = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" height="70" width="70" viewBox="0 0 576 512" fill='rgb(255,255,255,0.18)'>
            <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z" /></svg>
        )
    }
    const ArrowLeftIcon = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512">
            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
        )
    }
    const CircleImage = ({imageURL}:{imageURL:string}) => {
        return(
            <img src={imageURL} style={{width: 45, height: 45 ,background: 'purple', borderRadius:999, border: '1px rgba(0, 0, 0, 0.15) solid'}}  />
        )
    }

    return {DashboardIcon,VideoIcon,ChannelIcon,CommunityIcon,MoreIcon,LikeIcon,HelpIcon,YouTubeIcon,Location,FavoriteIcon,FavoriteActiveIcon,EqualizerIcon,EqualizerAnalyticsIcon,ThumbsUpIcon,PlayIcon,TrendingUpIcon,CheckIcon,PaperPlaneIcon,ListIcon,MusicIcon,IdCardIcon,TrophyIcon,IdBadgeIcon,AddressCardIcon,CreditCardIcon,TrashCanIcon,SignOutIcon,ArrowRightIcon,CircleIcon,CheckIconNew,CrownIcon,ArrowLeftIcon,CircleImage,PlusIcon}

}

export default Icons
