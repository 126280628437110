import React from 'react'
import asknaveen from '../../assets/logo/asknaveen.jpg'
import { Link } from "react-router-dom";

const Register = () => {
  return (
    <div>
      <div className='d-flex d-flex align-items-center justify-content-center' style={{height:'100vh'}}>
        <div className='container p-0'>
          <div className='' style={{textAlign: 'center', color: 'black', fontSize: '20px'}}>
            <img  className='' src={asknaveen} alt="" />
            <div className='mb-5 mt-4' style={{ height: '22px', textAlign: 'center', color: 'black', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word' }}>Please Register Here</div>
            <div className=''>
              <input className='mt-4' type="email" id="email" name="email" style={{border: 'none',borderBottom: '1px solid rgba(0, 0, 0, 0.15)',padding: '8px',fontSize: '16px',fontFamily: 'Roboto',fontWeight: 400,width: '80%',maxWidth:'400px',boxSizing: 'border-box',marginBottom: '20px',outline:'none'}} placeholder="Full Name" /><br/>
              <input type="email" name="email" style={{border: 'none',borderBottom: '1px solid rgba(0, 0, 0, 0.15)',padding: '8px',fontSize: '16px',fontFamily: 'Roboto',fontWeight: 400,width: '80%',maxWidth:'400px',boxSizing: 'border-box',marginBottom: '20px',outline:'none'}} placeholder="Email address" /><br/>


              <select style={{border: 'none',borderBottom: '1px solid rgba(0, 0, 0, 0.15)',padding: '8px',fontSize: '16px',fontFamily: 'Roboto',fontWeight: 400,width:'20%',maxWidth:'100px',boxSizing: 'border-box',marginBottom: '20px',outline:'none',paddingBlock:'10px'}}>
                  <option>+91</option>
                  <option>+93</option>
                  <option>+90</option>
              </select>
              <input type="email" name="email" style={{border: 'none',borderBottom: '1px solid rgba(0, 0, 0, 0.15)',padding: '8px',fontSize: '16px',fontFamily: 'Roboto',fontWeight: 400,width:'60%',maxWidth:'300px',boxSizing: 'border-box',marginBottom: '20px',outline:'none'}} placeholder="Mobile Number" /><br/>


              <div className='d-flex d-flex align-items-center justify-content-center'>
                  <div className='d-flex' style={{width:"80%",maxWidth:'400px'}}>
                      <input type="checkbox" name="email" style={{border: 'none',fontFamily: 'Roboto',boxSizing: 'border-box',marginBottom: '20px',outline:'none'}}/>
                      <div className='text-muted ms-2' style={{fontSize:"12px"}}>I agree to the term and coundition and privacy policy</div>
                  </div>
              </div>
              <button className='mt-4' style={{ width: '80%',maxWidth:'400px', height: '48px', left: '39px', top: '171px', background: '#FF0000', borderRadius: '10px',color: 'white', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 600, border:'none' }}>Submit</button>
              <div className='mt-3'>
                <label className='' style={{ color: 'rgba(0, 0, 0, 0.50)', fontSize: '14px', fontFamily: 'Roboto', fontWeight: 400}}>Already have an account <Link to='/login'> Login Here</Link></label> 
              </div>
            </div>
          </div>
        </div>
      </div>   
    </div>
  )
}

export default Register
