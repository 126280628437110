import React from 'react'
import Back from '../components/Back'

const AddChannel:React.FC = () => {
  return (
    <div className='container pb-5' style={{maxWidth:'800px'}}>
    <Back title='Add Channel'/>
    <div className='text-center mt-4'>
        <input className='mt-5' type="email" id="email" name="email" style={{border: 'none',borderBottom:'1px solid rgba(0, 0, 0, 0.15)',paddingTop:4,paddingBottom:4,fontSize: '16px',fontFamily: 'Roboto',fontWeight: 400,width: '80%',maxWidth:'400px',boxSizing: 'border-box',outline:'none'}} placeholder='Enter any of your Youtube video link'/><br/>
        <button className='mt-4' style={{ width: '80%',maxWidth:'200px', height: '40px', left: '39px', top: '171px', background: '#FF0000', borderRadius: '5px',color:'white', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 600, border:'none' }}>Add Channel</button>
    </div>
    </div>
  )
}

export default AddChannel
