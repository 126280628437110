import { createSlice,PayloadAction } from '@reduxjs/toolkit';

interface SortOption {
    label: string;
    value: string;
}
interface TabOption {
    label: string;
    value: string;
}

export const filtersSlice = createSlice({
    name:'filters',
    initialState:{
        countries: null,
        categories: null,
        tabItems:null,
        sortBy:null,
        videoType:null,
    },
    reducers:{
        setCountries: (state:any, action:any) => {
        state.countries = action.payload;
        },
        setCategories: (state:any, action:any) => {
        state.categories = action.payload;
        },
        setTabItems: (state:any, action:PayloadAction<TabOption[]>) => {
        state.tabItems = action.payload;
        },
        setSortBy: (state:any, action:PayloadAction<SortOption[]>) => {
        state.sortBy = action.payload;
        },
        setVideoType: (state:any, action:PayloadAction<SortOption[]>) => {
        state.videoType = action.payload;
        },
    }
    })