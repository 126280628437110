import React from 'react'
import { Routes, Route } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from './routes';
import AuthMiddleWare from './routes/routes';

export interface IRouteDto {
  path: string;
  component: any;
}

const App:React.FC= () => {
  return (
    <React.Fragment>
      <Routes>
      {authProtectedRoutes.map((route:IRouteDto, idx:number)=>(
        <Route path={route.path} element={<AuthMiddleWare>{route.component}</AuthMiddleWare>} key={idx}/>
      ))}
      {publicRoutes.map((route:IRouteDto, idx:number)=>(
        <Route path={route.path} element={route.component} key={idx}/>
      ))}
      </Routes> 
    </React.Fragment> 
  )
}

export default App
