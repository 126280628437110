import {createAsyncThunk} from '@reduxjs/toolkit'
import { Setting } from '../../api/setting';
const settingModel = new Setting()


export const settingValue = createAsyncThunk('settingValue', async(settingKey:string,{ rejectWithValue })=>{
    try {
        if(settingKey==='channelCountry'){
            const userDetailsRes:any = await settingModel.countries(settingKey)
            return userDetailsRes
        }
        if(settingKey==='videoCategoryKey'){
            const userDetailsRes:any = await settingModel.videoCategory(settingKey)
            return userDetailsRes
        }
        if(settingKey==='videoTypeKey'){
            const userDetailsRes:any = await settingModel.videoType(settingKey)
            return userDetailsRes
        }
        if(settingKey==='proBenefitsJson'){
            const userDetailsRes:any = await settingModel.proBenefits(settingKey)
            return userDetailsRes
        }
    } catch (error:any) {
        if(error.errors){
            return rejectWithValue({isValidationError:true, validationMessage:error.errors[0] || 'An error occurred during validation'});
        }
        return rejectWithValue(error.message)
    }
})
