import Navbar from "../components/Navbar"
import PerformanceTable from "../components/tables/PerformanceTable"
import AddChannel from "../pages/AddChannel"
import ChannelDetailsPage from "../pages/ChannelDetailsPage"
import ChannelVideos from "../pages/ChannelVideos"
import MembershipPage from "../pages/MembershipPage"
import Performance from "../pages/Performance"
import Login from "../pages/auth/Login"
import Register from "../pages/auth/Register"
import Error404 from "../pages/errors/Error404"
import Channels from "../pages/main/Channels"
import Community from "../pages/main/Community"
import Dashboard from "../pages/main/Dashboard"
import Main from "../pages/main/Main"
import More from "../pages/main/More"
import User from "../pages/main/User"
import Videos from "../pages/main/Videos"

const authProtectedRoutes = [
    {path:'/user', component:<User/>},
    {path:'/dashboard/:channelID', component:<Main PAGE={<Dashboard/>}/>},
    {path:'/dashboard', component:<Main PAGE={<Dashboard/>}/>},
    {path:'/videos', component:<Main PAGE={<Videos/>}/>},
    {path:'/channels', component:<Main PAGE={<Channels/>}/>},
    {path:'/community', component:<Main PAGE={<Community/>}/>},
    {path:'/more', component:<Main PAGE={<More/>}/>},
    {path:'/performance/:ytChannelID', component:<Performance/>},
    {path:'/channelvideos/:ytChannelID', component:<ChannelVideos/>},
    {path:'/channel/:channelID', component:<ChannelDetailsPage/>},
    {path:'/membership', component:<MembershipPage/>},
    {path:'/add', component:<AddChannel/>},
]

const publicRoutes = [ 
    {path:'/login',component:<Login/>},
    {path:'/register', component:<Register/>},
    {path:'*',component:<Error404/>},
]

export {authProtectedRoutes,publicRoutes}