import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hooks/hooks'
import { channelHistory } from '../features/channel/actions';
import { handleYtChannelID } from '../features/video/actions';
 

const AdvAnalytics:React.FC <any> = ({icon,title,arrow,type}:{icon:any,title:string,arrow:any,type:string}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {data:channelVideoData,isLoading:isChannelVidLoading,query:cvQuery} = useAppSelector((state:any)=>state.video.channelVideos)
  const {data:channelDetails,isLoading:isChannelDetailsLoading} = useAppSelector((state:any)=>state.channel.channelDetails)
  const [channelDetailsData] = channelDetails?channelDetails:''
  // console.log(channelDetailsData)

  const handleClick = (type: string) => {
    const ytChannelID = channelDetailsData?channelDetailsData.ytChanelID:null
    switch (type) {
      case 'performance':
        // navigate(`/performance/${channelDetailsData.ytChannelID}`);
        navigate(`/performance/${ytChannelID}`);
        // dispatch(channelHistory(''))
        break;
      case 'videos':
        // navigate(`/channelvideos/${channelDetailsData.ytChannelID}`);
        navigate(`/channelvideos/${ytChannelID}`);
        // console.log(channelDetailsData.id)
        // dispatch(handleYtChannelID(channelDetailsData.ytChannelID))
        // dispatch(handleYtChannelID('UC4wEPe5mrHGAUjjTfXH_C-Q'))
        break;
      default:
        // Handle default case or do nothing
        break;
    }
  };
  return (
    <div onClick={()=>{handleClick(type)}} className='d-flex justify-content-between align-items-center p-2 ps-2 pe-2' >
        <div className='d-flex'>
        <div>{icon} </div>
        <div className='ms-2' style={{fontSize:15}}>{title}</div>
        </div>
        <div className='p-1'>{arrow()}</div>
    </div>
  )
}

export default AdvAnalytics
