import React from 'react'
import Icons from '../components/Icons'
import Opinion from './Opinion'

const Video1:React.FC <any> = ({title,name,views,dailyViews,likes,date,imageURL,isOpinion}:{title:string,name:string,views:string,dailyViews:string,likes:string,date:string,imageURL:string,isOpinion:boolean}) => {
    const {PlayIcon,EqualizerIcon,ThumbsUpIcon,CheckIcon,TrendingUpIcon} = Icons()
    return (
            <div className='col-12 col-sm-6 col-md-6 p-0 ps-2 pe-2'>
                <div className='d-flex'>
                    <div style={{ position: 'relative', width: 130, height: 90, transformOrigin: '0 0', background: '#EFF1F2', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img style={{ position: 'relative', width: 130, height: 90, transformOrigin: '0 0', background: '#EFF1F2', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }} src={imageURL}/>
                        <div className='me-1 mb-1' style={{ position: 'absolute',borderRadius:4,color:'white',paddingLeft:4,paddingRight:4,fontSize:12,fontFamily:'Roboto'}}><PlayIcon/></div>
                        <div className='me-1 mb-1' style={{ position: 'absolute', bottom: 0, right: 0, backgroundColor:'black', borderRadius:4,color:'white',paddingLeft:4,paddingRight:4,fontSize:12,fontFamily:'Roboto'}}>00:18</div>
                    </div>
                    <div className='m-0 p-0 ms-2'>
                        <div style={{color:'black', fontSize:15, fontWeight:500}}>{title}<TrendingUpIcon/></div>
                        <div style={{fontSize:13,fontFamily:'Roboto',fontWeight:400}}>{name}</div>
                        <div className='d-flex' style={{fontSize:13,fontFamily:'Roboto',fontWeight:400}}>
                        <div className='d-flex me-2'>
                            <EqualizerIcon color='gray'/>  
                            <div>{views}</div>
                        </div>
                        <div className='d-flex'>
                            <ThumbsUpIcon/>
                            <div>{likes}</div>
                        </div>
                        </div>
                        <div style={{fontSize:13,fontFamily:'Roboto',fontWeight:400}}>{date}</div>
                    </div>
                </div>
                {isOpinion && <Opinion/>}
            </div>
    )
}

export default Video1
