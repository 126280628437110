import React from 'react'

const ErrorModal = ({ isOpen, onClose, children }:{isOpen:any,onClose:any,children:any}) => {
    
    const modalStyle:any = {
        display: isOpen ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        opacity: isOpen ? 1 : 0,
        transition: 'opacity 0.8s ease-in-out'
      };
    
      const modalContentStyle:any = {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
        position: 'relative',
      };
    
      const closeStyle:any = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        fontSize: '20px',
        cursor: 'pointer',
      };
    
      return (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            {/* <span style={closeStyle} onClick={onClose}>
              &times;
            </span> */}
            {children}
          </div>
        </div>
      );
    };

export default ErrorModal
