import React from 'react'
import Icons from './Icons'
import { Global } from '../utils/global'
import { useAppSelector, useAppDispatch } from '../hooks/hooks'
import { useNavigate } from 'react-router-dom'
import LoadingFullWindow from './Loaders/LoadingFullWindow'

const Membership:React.FC  = () => {
    const globalModel = new Global();
    const navigate = useNavigate()
    const {data:userDetailsData,isLoading:isUserDetailsLoading} = useAppSelector((state:any)=>state.user.userDetails)
    const {CrownIcon} = Icons();
    return (
        <div className='col-12 col-sm-6 p-0 pe-2 pe-sm-3 mb-4' onClick={()=>{navigate('/membership')}}>
            <div className='mb-2' style={{fontWeight:600}}>Membership</div>
            {
            !isUserDetailsLoading && userDetailsData &&
            <div className='d-flex justify-content-between align-items-center p-1 ps-2 pe-2' style={{backgroundImage:'linear-gradient(to right, #756BFF , #F43387)',color:'white',borderRadius:8}}>
                <div>
                <div style={{fontFamily:'Roboto',fontSize:14,opacity:0.5}}>CURRENT PLAN</div>
                <div style={{fontSize:20,fontWeight:600}}>PREMIUM</div>
                <div style={{fontFamily:'Roboto',fontSize:12,opacity:0.5,textDecoration:'underline'}}>Valid till {globalModel.getDateMonthYear(userDetailsData.membershipExpiryDate)} ({globalModel.getDaysDifference(userDetailsData.membershipExpiryDate)} days left)</div>
                </div>
                <div className='p-1' style={{transform:'rotate(30deg)'}}><CrownIcon/></div>
            </div>
            }
            {/* {isUserDetailsLoading &&<div>...Loading</div>} */}
            {isUserDetailsLoading?<LoadingFullWindow/>:null}
        </div>
  )
}

export default Membership
