import React, { useEffect } from 'react'
import Icons from '../../components/Icons'
import UpdatedTime from '../../components/UpdatedTime'
import Analytic from '../../components/Analytic'
import useAuthUtils from '../../utils/authUtils'
import useTokenUtils from '../../utils/tokenUtils'
import { useAppSelector, useAppDispatch } from '../../hooks/hooks'
import { Global } from '../../utils/global'
import AdvAnalyticsContainer from '../../components/AdvAnalyticsContainer'
import Membership from '../../components/Membership'
import LoadingFullWindow from '../../components/Loaders/LoadingFullWindow'
import ChannelDetails from '../../components/ChannelDetails'
import { userDetails } from '../../features/user/actions'
import { channelDetails, myChannels } from '../../features/channel/actions'
import { useParams,useNavigate } from 'react-router-dom';
import Video from '../../components/Video'
import { channelVideos, communityVideos } from '../../features/video/actions'

const Dashboard:React.FC = () => {
  const globalModel = new Global();
  const { checkAndDispatchIsILogin } = useAuthUtils();
  const {clearTokenFromLocalStorage,getTokenFromLocalStorage} = useTokenUtils()
  const {LikeIcon,HelpIcon,CrownIcon,ArrowRightIcon,EqualizerIcon,TrendingUpIcon,PlusIcon} = Icons()
  const dispatch = useAppDispatch()
  const { channelID } = useParams();
  const navigate = useNavigate();
  const {data:chDetailsData,isLoading:isChannelDetailsLoading} = useAppSelector((state:any)=>state.channel.channelDetails)
  const [channelDetailsData] = chDetailsData?chDetailsData:''
  const {data:myChannelsData} = useAppSelector((state:any)=>state.channel.myChannels) 
  const myChannelFirst = myChannelsData?myChannelsData[0]:''
  const {data:communityVideosData,isLoading:isCommunitiVideosLoading,body:communityVideoBody} = useAppSelector((state:any)=>state.video.communityVideos)

  console.log(chDetailsData)

  const anlyticsData = [
    {title:'subscribers',data: channelDetailsData && globalModel.getNumber(channelDetailsData.subscriber),toolTip:'Aggregate count of subscribers, data sourced from the Youtube public api'},
    {title:'views',data: channelDetailsData && globalModel.getNumber(channelDetailsData.views),toolTip:'The total video views coutn and its change in the last day views count, data sourced from the youtube public api.'},
    {title:'Avg Subscribers',data:channelDetailsData && globalModel.getNumber(channelDetailsData.subscriber/channelDetailsData.videos),toolTip:'Calculation: Subscribers divided by total videos using data from the youtube public api'},
    {title:'Videos',data:channelDetailsData && globalModel.getNumber(channelDetailsData.videos),toolTip:'Count of publicly avalilble video and its change in the last day videos count, data sourced from the youtube public api.'},
    {title:'Average Views',data:channelDetailsData && globalModel.getNumber(channelDetailsData.views/channelDetailsData.videos),toolTip:'Calculation: Total views divided by total videos using data from youtube public api.'},
    {title:'Category',data:channelDetailsData &&channelDetailsData.channelCategory, toolTip:'Data updated by user or drived from top video category on the channel.'},
    {title:'Channel Monetized',data:channelDetailsData && channelDetailsData.isChannelMonetize==='Y'?'Yes':'No', toolTip:'Data is updated by the user or support team when the channel meets youtube monetization criteria and display ads.'},
    {title:'Level',data:channelDetailsData &&channelDetailsData.level,toolTip:'The AskNaveen app has 6 levels categorized by totla subscribers: Level-1(0-1000), Level-2(1K-10K),Level-3(10K-100K),Level-4(100K-1M),Level-5(1M-10M) and Level-6(10M+)'},
  ]

  useEffect(()=>{
    if(myChannelFirst.ytChannelID){
      if(window.location.pathname=='/dashboard'){
        navigate(`/dashboard/${myChannelFirst.id}`)
      }
    }
    // const pathAfterDashboard = window.location.pathname.replace('/dashboard', '').trim();
    // if(pathAfterDashboard===''){
    //   navigate(`/dashboard/${myChannelFirst.ytChannelID}`)
    // }
    if(channelID){
      dispatch(channelDetails(channelID))
      const body = {userVideoStatus:communityVideoBody.userVideoStatus,channelId:channelID}
      dispatch(communityVideos({token:getTokenFromLocalStorage(),body:body}))
    }
  },[channelID,myChannelFirst])



  
  return (
      <div className='container pb-5' style={{maxWidth:'800px'}}>
        {channelDetailsData && 
        <ChannelDetails
        imageURL = {channelDetailsData.channelImage}
        userName={channelDetailsData.channelName}
        channelName={channelDetailsData.handle}
        channelID = {channelDetailsData.id}
        location = {`${channelDetailsData.city},${channelDetailsData.state},${channelDetailsData.country}`}
        />}
        {channelDetailsData &&
        <UpdatedTime
        updatedAt={`Updated: ${channelDetailsData && globalModel.getDateMonth(channelDetailsData.updateDateTime)}`}
        heading='Channel Analytics'
        />
        }
        <div className='row mt-2'>
          {
          channelDetailsData &&
          anlyticsData.map((value:any,index:number)=>{
          return(
          <Analytic
          title={value.title}
          data={value.data}
          toolTip={value.toolTip}
          />
          )
          })
          }
        </div>  
        <div className='row ps-2 mt-4'>
          {
          channelDetailsData &&
          <>
          <Membership/>
          <AdvAnalyticsContainer/>
          </>
          }
        </div> 

        <div className='row ps-1 ps-md-0 mt-3'>
          {communityVideosData && communityVideosData.length>0 &&
          <div className='col-12 p-0 ps-2 mb-2' style={{fontWeight:600}}>
            Latest Videos ({communityVideosData.length})
          </div>}
          {
          communityVideosData &&
          communityVideosData.map((value:any,index:number)=>{
          return(
          <Video
          title={value.videoTitle}
          views={globalModel.getNumber(value.views)}
          likes={globalModel.getNumber(value.likes)}
          date={globalModel.getDaysAgoTime(value.videoPublishDateTime)}
          imageURL={value.ytVideoId}
          // trendingUpIcon={<TrendingUpIcon/>}
          // equalizerIcon={<EqualizerIcon color='gray'/>}
          // dailyViews={globalModel.getNumber(value.dailyViews)}
          />
          )
          })
          }
        </div>  

        <div className='row'>
          <div className='d-flex justify-content-center align-items-center' style={{height:'70vh'}}>
            <div className='text-center'>
            <PlusIcon color='gray'/>
            <div>Add Channel</div>
            </div>
          </div>
        </div>

        {isChannelDetailsLoading?<LoadingFullWindow/>:null}
      </div>
  )
}

export default Dashboard
