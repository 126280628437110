export const mode = null
export const HOST = mode==='dev'?'http://localhost:9721':'https://asknaveenjoshi.com'

// authentication api 
export const sendOtp_URL = `${HOST}/nodeapi/user/generateotp`
export const verifyOtp_URL = `${HOST}/nodeapi/user/login`
export const isILogin_URL = `${HOST}/nodeapi/user/validation`

// video api 
export const channelVideos_URL = `${HOST}/nodeapi/video/channelvideos`
export const communityVideos_URL = `${HOST}/nodeapi/uservideo/community`
export const videos_URL = `${HOST}/nodeapi/adminvideo`

// channel api 
export const myChannels_URL = `${HOST}/nodeapi/channel/mychannel`
export const channelDetails_URL = `${HOST}/nodeapi/channel`
export const channelHistory_URL = `${HOST}/nodeapi/channel/history`
export const channels_URL = `${HOST}/nodeapi/channel`
export const favouriteChannels_URL = `${HOST}/nodeapi/channel/`
export const favouriteChannelsIdsArr_URL = `${HOST}/nodeapi/channel/favourite`

// setting api 
export const settingValues_URL = `${HOST}/nodeapi/setting/key`

// user details 
export const userDetails_URL = `${HOST}/nodeapi/user/details`

