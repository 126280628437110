import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { channelDetails, channelHistory, channels, favChannelsIdsArr, myChannels } from './actions';

export const channelHistorySlice = createSlice({
  name:'channelHistory',
  initialState:{
      data:null,
      isLoading:false,
      isError:false,
      errorMessage:null,
  },
  extraReducers:(builder) => {
    builder.addCase(channelHistory.pending, (state, action)=>{
      state.isLoading = true;
    })
    builder.addCase(channelHistory.fulfilled, (state, action)=>{
        state.isLoading = false;
        state.data = action.payload;
    })
    builder.addCase(channelHistory.rejected, (state:any, action:any)=>{
        if(action.payload.isValidationError){
          state.isLoading = false;
          state.isValidationError = true
          state.validationMessage = action.payload.validationMessage
        }else{
          console.log("error", action.payload, action.error.message || 'An error occurred');
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload
        }
    })
  },
  reducers:{
    // handleInput:(state:any,action)=>{
    //     const name = action.payload.name
    //     const value = action.payload.value
    //     state.credentials[name] = value
    // },
    // resetSendOtp:(state)=>{
    //   state.data = null;
    //   state.isLoading = false;
    //   state.isError = false;
    //   state.errorMessage = null;
    // },
    // resetSendOtpError:(state)=>{
    //   state.data = null;
    //   state.isLoading = false;
    //   state.isError = false;
    //   state.errorMessage = null;
    // }
  }
})
export const myChannelsSlice = createSlice({
  name:'myChannels',
  initialState:{
      data:null,
      isLoading:false,
      isError:false,
      errorMessage:null,
  },
  extraReducers:(builder) => {
    builder.addCase(myChannels.pending, (state, action)=>{
      state.isLoading = true;
    })
    builder.addCase(myChannels.fulfilled, (state, action)=>{
        state.isLoading = false;
        state.data = action.payload;
    })
    builder.addCase(myChannels.rejected, (state:any, action:any)=>{
        if(action.payload.isValidationError){
          state.isLoading = false;
          state.isValidationError = true
          state.validationMessage = action.payload.validationMessage
        }else{
          console.log("error", action.payload, action.error.message || 'An error occurred');
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload
        }
    })
  },
  reducers:{
    handleInput:(state:any,action)=>{
        const name = action.payload.name
        const value = action.payload.value
        state.credentials[name] = value
    },
    resetSendOtp:(state)=>{
      state.data = null;
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = null;
    },
    resetSendOtpError:(state)=>{
      state.data = null;
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = null;
    }
  }
})
export const channelDetailsSlice = createSlice({
name:'channelDetails',
initialState:{
    data:null,
    isLoading:false,
    isError:false,
    errorMessage:null,
},
extraReducers:(builder) => {
  builder.addCase(channelDetails.pending, (state, action)=>{
    state.isLoading = true;
  })
  builder.addCase(channelDetails.fulfilled, (state, action)=>{
      state.isLoading = false;
      state.data = action.payload;
  })
  builder.addCase(channelDetails.rejected, (state:any, action:any)=>{
      if(action.payload.isValidationError){
        state.isLoading = false;
        state.isValidationError = true
        state.validationMessage = action.payload.validationMessage
      }else{
        console.log("error", action.payload, action.error.message || 'An error occurred');
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload
      }
  })
},
reducers:{
  }
})
export const channelsSlice = createSlice({
  name:'channels',
  initialState:{
      data:null,
      isLoading:false,
      isError:false,
      errorMessage:null,
      params:{
        page:0,
        prevPage:0,
        pageLimit:50,
        videoType:'',
        country:"IN",
        categoryId:'',
        sortBy:'',
        isChannelVerified:'Y',
        isFavourite:'',
        search:''
      }
  },
  extraReducers:(builder) => {
    builder.addCase(channels.pending, (state, action)=>{
      state.isLoading = true;
    })
    builder.addCase(channels.fulfilled, (state:any, action:any)=>{
        state.isLoading = false;
        if(action.payload){
          if(!state.data){
            state.data = action.payload
          }
          if(state.data && state.params.page!=0){
            state.data = [...state.data, ...action.payload]
          }
        }
    })
    builder.addCase(channels.rejected, (state:any, action:any)=>{
        if(action.payload.isValidationError){
          state.isLoading = false;
          state.isValidationError = true
          state.validationMessage = action.payload.validationMessage
        }else{
          console.log("error", action.payload, action.error.message || 'An error occurred');
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload
        }
    })
  },
  reducers:{
    handleFilters:(state,action)=>{
      const {type,value} = action.payload
      switch (type) {
        case 'country':
          state.params.country = value;
          break;
        case 'category':
          state.params.categoryId = value;
          break;
        case 'sortBy':
          state.params.sortBy = value;
          break;

        case 'page':
          state.params.page += value
          break;

        case 'channelTab':
          if(value==='verified'){
            state.params.page = 0
            state.data = null
            state.params.country = 'IN';
            state.params.isFavourite = ''
            state.params.isChannelVerified = 'Y'
          }
          if(value==='all'){
            state.params.page = 0
            state.data = null
            state.params.country = 'IN';
            state.params.isFavourite = ''
            state.params.isChannelVerified = ''
          }
          if(value==='favourite'){
            state.params.page = 0
            state.data = null
            state.params.country = '';
            state.params.isFavourite = 'Y'
            state.params.isChannelVerified = ''
          }
          break;
        case 'videoType':
          state.params.videoType = value
          break;
        default:
          break;
      }
    } 
  }
})

export const favChannelsIdsArrSlice = createSlice({
  name:'favChannelsIdsArr',
  initialState:{
      data:null,
      isLoading:false,
      isError:false,
      errorMessage:null,
  },
  extraReducers:(builder) => {
    builder.addCase(favChannelsIdsArr.pending, (state, action)=>{
      state.isLoading = true;
    })
    builder.addCase(favChannelsIdsArr.fulfilled, (state, action)=>{
        state.isLoading = false;
        state.data = action.payload;
    })
    builder.addCase(favChannelsIdsArr.rejected, (state:any, action:any)=>{
        if(action.payload.isValidationError){
          state.isLoading = false;
          state.isValidationError = true
          state.validationMessage = action.payload.validationMessage
        }else{
          console.log("error", action.payload, action.error.message || 'An error occurred');
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload
        }
    })
  },
  reducers:{}
})


