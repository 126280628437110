import React, { useEffect, useState } from 'react'
import Icons from '../../components/Icons'
import Channel from '../../components/Channel'
import TabBar from '../../components/TabBar'
import Search from '../../components/Search'
import Select from '../../components/Select'
import useAuthUtils from '../../utils/authUtils'
import useTokenUtils from '../../utils/tokenUtils'
import { useAppSelector, useAppDispatch } from '../../hooks/hooks'
import { setCategories, setCountries, setSortBy, setTabItems, setVideoType } from '../../features/filters/actions'
import { channelTabItems,sortByItems,videoTypeItems } from '../../utils/constants'
import { settingValue } from '../../features/setting/actions'
import { channels, favChannelsIdsArr, handleFilters } from '../../features/channel/actions'
import { Global } from '../../utils/global'
import ScrollContainer from '../../components/ScrollContainer'
import LoadingFullWindow from '../../components/Loaders/LoadingFullWindow'

const Channels:React.FC = () => {
  const {FavoriteIcon,FavoriteActiveIcon} = Icons()
  const dispatch = useAppDispatch()
  const {getTokenFromLocalStorage} = useTokenUtils()
  const globalModel = new Global()
  const {data:settingValueData, isLoading:isSettingValueLoading} = useAppSelector((state:any)=>state.setting.settingValue)
  const {countries,categories,tabItems,sortBy,videoType} = useAppSelector((state:any)=>state.filters.filters)
  const {data:channelsData,isLoading:isChannelDataLoading,params:channelParams} = useAppSelector((state:any)=>state.channel.channels)
  const {data:favIdsArr,isLoading:isFavChannelsIdsArrLoading} = useAppSelector((state:any)=>state.channel.favChannelsIdsArr)
  console.log(channelParams)
  // console.log(favIdsArr)


  useEffect(()=>{
    if(!settingValueData){
      dispatch(settingValue('channelCountry'))
      dispatch(settingValue('videoCategoryKey'))
      dispatch(settingValue('videoTypeKey'))
    }
    if(!favIdsArr){
      dispatch(favChannelsIdsArr(getTokenFromLocalStorage()))
    }
    if(settingValueData){
      if (settingValueData.countries) {
        dispatch(setCountries(settingValueData.countries));
      }
      if(settingValueData.categories){
        dispatch(setCategories(settingValueData.categories))
      }
      if(channelTabItems){
        dispatch(setTabItems(channelTabItems))
      }
      if(sortByItems){
        dispatch(setSortBy(sortByItems))
      }
      if(videoTypeItems){
        dispatch(setVideoType(videoTypeItems))
      }
    }
  },[settingValueData,favIdsArr])

  useEffect(()=>{
    dispatch(channels({params:channelParams,token:getTokenFromLocalStorage()}))
  },[channelParams.page, 
    channelParams.country, 
    channelParams.categoryId, 
    channelParams.sortBy, 
    channelParams.isChannelVerified, 
    channelParams.videoType, 
    channelParams.isFavourite
  ])


  return (
    <div className='container' style={{maxWidth:'800px'}}>
      {/* <button onClick={()=>{dispatch(handleFilters({type:'page',value:1}))}} >click</button> */}
      <div className='row'>
        <TabBar
        tabData={tabItems && tabItems}
        type='channels'
        filterType='channelTab'
        selected='verified'
        />
      </div>
      <div className='row mt-1 mb-4'>
        <div className='overflow-auto p-0'>
          <div className='d-flex justify-content-md-center ps-2'>
            {/* <Search/> */}
            <Select
            selectData={countries && countries}
            type='channels'
            filterType='country'
            />
            <Select
            selectData={categories && categories}
            type='channels'
            filterType='category'
            />
            <Select
            selectData={sortBy && sortBy}
            type='channels'
            filterType='sortBy'
            />
            <Select
            selectData={videoType && videoType}
            type='channels'
            filterType='videoType'
            />
          </div>
        </div>
      </div>
      <ScrollContainer type='channels'>
        <div className='row'>
          {
          channelsData &&
          channelsData.map((value:any,index:number)=>{
          return(
            <Channel
            type='channels'
            id={value.id}
            channelName={value.channelName}
            subscribers={globalModel.getNumber(value.subscriber)}
            views={globalModel.getNumber(value.views)}
            dailyViews={`+${globalModel.getNumber(value.dailyViews)}`}
            imageURL={value.channelImage}
            favouriteIcon={globalModel.isChannelFav(favIdsArr,value.id)?<FavoriteActiveIcon/>:<FavoriteIcon/>}
            />
          )
          })
          }
        </div>
      </ScrollContainer>
      {isChannelDataLoading?<LoadingFullWindow/>:null}
    </div>
  )
}

export default Channels
