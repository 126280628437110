import React, { useEffect } from 'react'
import Membership from '../components/Membership'
import { useAppSelector, useAppDispatch } from '../hooks/hooks'
import useTokenUtils from '../utils/tokenUtils'
import { userDetails } from '../features/user/actions'
import MembershipBenefits from '../components/MembershipBenefits'
import Back from '../components/Back'

const MembershipPage = () => {
  const dispatch = useAppDispatch()
  const {getTokenFromLocalStorage} = useTokenUtils()

  useEffect(()=>{
    dispatch(userDetails(getTokenFromLocalStorage()))
  },[])

  return (
    <div className='container pb-5' style={{maxWidth:'800px'}}>
    <Back/>
    <div className='d-sm-flex mt-4'>
      <Membership/>
      <MembershipBenefits/>
    </div>
    </div>
  )
}

export default MembershipPage
