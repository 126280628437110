import axios,{AxiosError} from 'axios';
import { userDetails_URL } from '../utils/url_helper';

export class User{

    userDetails = async (token:string) => {
        try {
            // const url = `http://localhost:9721/nodeapi/user/details`
            const url = userDetails_URL
            const userDetailsRes = await axios(url, {
                headers: {'Authorization': `Token ${token}`},
                method:'post'
            })
            return userDetailsRes
        } catch (error:any) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }
}