import React, { useEffect, useState ,useRef} from 'react'
import Back from '../components/Back'
import Video1 from '../components/Video1'
import { useAppSelector, useAppDispatch } from '../hooks/hooks'
import { channelVideos, handleYtChannelID } from '../features/video/actions'
import Video from '../components/Video'
import ScrollContainer from '../components/ScrollContainer'
import LoadingFullWindow from '../components/Loaders/LoadingFullWindow'
import { Global } from '../utils/global'
import { useParams } from 'react-router-dom';
import Search from '../components/Search'
import Select from '../components/Select'
import Icons from '../components/Icons'

const ChannelVideos:React.FC = () => {
    const dispatch = useAppDispatch()
    const globalModel = new Global()
    const {TrendingUpIcon,EqualizerIcon} = Icons()
    const {data:channelVideosData,isLoading:isChannelVidLoading,query:cvQuery} = useAppSelector((state:any)=>state.video.channelVideos)
    // const {data:channelDetails,isLoading:isChannelDetailsLoading} = useAppSelector((state:any)=>state.dashboard.channelDetails)
    // const [channelDetailsData] = channelDetails?channelDetails:''

    const filterData = [
        {value:'dailyViews',label:'Last Day Views'},
        {value:'videoPublishDateTime',label:'Latest'},
        {value:'views',label:'Popular'},
        {value:'popular',label:'Trending'},
    ]

    const { ytChannelID } = useParams();

    useEffect(()=>{
        const paramsObject = {ytChannelId:ytChannelID,page:cvQuery.page, pageLimit:cvQuery.pageLimit,sortBy:cvQuery.shortBy};  
        dispatch(channelVideos(paramsObject))
    },[cvQuery.pageLimit,cvQuery.shortBy])

  return (
    <ScrollContainer type='channelVideos'>
    <div style={{ height: '100vh'}}> {/* color  */}
        <div className='p-0' style={{height:'100vh',position:'relative'}}> {/* color  */}
            <div style={{height:'74%'}}>
                <div className='container' style={{maxWidth:'800px'}}>
                    <Back/>
                    <div className='row m-0 p-0 mt-2'>
                        <div className='d-flex justify-content-between m-0 p-0'>
                            <Search/>
                            <Select
                            selectData={filterData}
                            type='channelvideos'
                            />
                        </div>
                    </div>
                    <div className='row mt-5'>
                    {
                    channelVideosData && channelVideosData.data &&
                    channelVideosData.data.map((value:any,index:number)=>{
                    return(
                    <Video
                    title={value.videoTitle}
                    views={globalModel.getNumber(value.views)}
                    dailyViews={globalModel.getNumber(value.dailyViews)}
                    likes={globalModel.getNumber(value.likes)}
                    date={globalModel.getDateMonthYear(value.addDateTime)}
                    imageURL={value.ytVideoId}
                    trendingUpIcon={<TrendingUpIcon/>}
                    equalizerIcon={<EqualizerIcon color='gray'/>}
                    />
                    )
                    })
                    }
                    </div>
                </div>  
            </div>
        </div>
    </div>
    {isChannelVidLoading?<LoadingFullWindow/>:null}
    </ScrollContainer>
  )
}

export default ChannelVideos
