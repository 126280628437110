import axios,{AxiosError} from 'axios';
import { channelVideos_URL, communityVideos_URL, videos_URL } from '../utils/url_helper';

export class Video{

    channelVideos = async (params:any) => {
        const paramsString = new URLSearchParams(params).toString();
        try {
            // const url = `http://localhost:9721/nodeapi/video/channelvideos/?ytChannelId=UC4wEPe5mrHGAUjjTfXH_C-Q&page=0&pageLimit=50&sortBy=popular`
            // const url = `http://localhost:9721/nodeapi/video/channelvideos/?${paramsString}`
            const url = `${channelVideos_URL}/?${paramsString}`
            const channelVideosRes = await axios.get(url)
            return channelVideosRes
        } catch (error:any) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }

    communityVideos = async ({token,body}:{token:string,body:any}) => {
        try {
            const {userVideoStatus,channelId} = body
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            };
            // const url = 'http://localhost:9721/nodeapi/uservideo/community'
            const url = communityVideos_URL
            const data = new URLSearchParams();
            data.append('userVideoStatus', userVideoStatus);
            data.append('channelId', channelId);
            const response = await axios.post(url,data,{headers});
            return response
        } catch (error:any) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }

    videos = async (params:any) => {
        const paramsString = new URLSearchParams(params).toString();
        try {
            // const url = `http://localhost:9721/nodeapi/adminvideo?page=0&pageLimit=10 &videoType=Trending`
            // const url = `http://localhost:9721/nodeapi/adminvideo?${paramsString}`
            const url = `${videos_URL}?${paramsString}`
            const channelVideosRes = await axios.get(url)
            return channelVideosRes
        } catch (error:any) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }

}