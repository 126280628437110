import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { userDetails } from './actions';

export const userDetailsSlice = createSlice({
  name:'userDetails',
  initialState:{
      data:null,
      isLoading:false,
      isError:false,
      errorMessage:null,
  },
  extraReducers:(builder) => {
    builder.addCase(userDetails.pending, (state, action)=>{
      state.isLoading = true;
    })
    builder.addCase(userDetails.fulfilled, (state, action)=>{
        state.isLoading = false;
        state.data = action.payload;
    })
    builder.addCase(userDetails.rejected, (state:any, action:any)=>{
        if(action.payload.isValidationError){
          state.isLoading = false;
          state.isValidationError = true
          state.validationMessage = action.payload.validationMessage
        }else{
          console.log("error", action.payload, action.error.message || 'An error occurred');
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload
        }
    })
  },
  reducers:{
    // handleInput:(state:any,action)=>{
    //     const name = action.payload.name
    //     const value = action.payload.value
    //     state.credentials[name] = value
    // },
    // resetSendOtp:(state)=>{
    //   state.data = null;
    //   state.isLoading = false;
    //   state.isError = false;
    //   state.errorMessage = null;
    // },
    // resetSendOtpError:(state)=>{
    //   state.data = null;
    //   state.isLoading = false;
    //   state.isError = false;
    //   state.errorMessage = null;
    // }
  }
})