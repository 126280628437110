import { createSlice } from '@reduxjs/toolkit'

export interface ISendOtpToEmailState{
    showToast:boolean
}

export const toastSlice = createSlice({
    name:'toast',
    initialState:{
        showToast:false
    },
    extraReducers:(builder) => {
        
    },
    reducers:{
        handleShowToast:(state,action)=>{
            // console.log('clicked')
            state.showToast = action.payload
            // setTimeout(() => {
            //     state.showToast = false
            // }, 5000);
        }
    }
})