import React, { useEffect, useState } from 'react'
import { Navigate } from "react-router"
import {ReactNode} from 'react'
import { useAppSelector, useAppDispatch } from '../hooks/hooks'
import axios from 'axios';
import useAuthUtils from '../utils/authUtils';
import LoginLoader from '../components/Loaders/LoginLoader';
import LoadingFullWindow from '../components/Loaders/LoadingFullWindow';

interface IAuthMiddleware {
  children: React.ReactNode;
}
  
const AuthMiddleware: React.FC<IAuthMiddleware> = (props) => {
  const {checkAndDispatchIsILogin} = useAuthUtils()
  const {isLoading,isError,isILogin,ILoginMessage} = useAppSelector((state:any)=>state.auth.getIsILogin);
  useEffect(()=>{
    checkAndDispatchIsILogin()
  },[])
  if(isLoading){
    if(isError){
      return <Navigate to={{ pathname: '/login' }} />;
    }
    // return <LoginLoader/>
    return <LoadingFullWindow/>
  }
  if(!isILogin){
    console.log('Redirecting to login...',isILogin);
    return <Navigate to={{ pathname: '/login' }} />;
  }  
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AuthMiddleware;













// export interface IAuthMiddlware {
//     children: ReactNode;
// }
// const {isLoading:isGetIsILoginLoading,isError:isGetIsILoginError,isILogin,ILoginMessage} = useAppSelector((state:any)=>state.auth.getIsILogin);

// const AuthMiddleWare:React.FC <IAuthMiddlware> = async (props) => {
    
//     const [isILogin,setIsILogin] = useState(false)
//     const url = `http://localhost:9721/nodeapi/user/validation`
//     const token = localStorage.getItem('token');
//     const isILoginRes = await axios(url, {
//         headers: {'Authorization': `Token ${token}`},
//         method:'post'
//     })

//     if(!isILogin){
//         return(
//             <Navigate to={{pathname:'/login'}} />
//         );
//     }

//   return (
//     <React.Fragment>
//         {props.children}
//     </React.Fragment>
//   )
// }

// export default AuthMiddleWare










