import {createAsyncThunk} from '@reduxjs/toolkit'
import { Video } from '../../api/video'
import { channelVideosSlice, videosSlice } from './slices'
const videoModel = new Video()


export const channelVideos = createAsyncThunk('channelVideos', async(params:any,{ rejectWithValue })=>{
    try {
        const channelVideosRes:any = await videoModel.channelVideos(params)
        return channelVideosRes.data
    } catch (error:any) {
        if(error.errors){
            return rejectWithValue({isValidationError:true, validationMessage:error.errors[0] || 'An error occurred during validation'});
        }
        return rejectWithValue(error.message)
    }
})

export const communityVideos = createAsyncThunk('communityVideos', async({token,body}:{token: any; body: any},{ rejectWithValue })=>{
    try {
        const communityVideosRes:any = await videoModel.communityVideos({token,body})
        return communityVideosRes.data.data
    } catch (error:any) {
        if(error.errors){
            return rejectWithValue({isValidationError:true, validationMessage:error.errors[0] || 'An error occurred during validation'});
        }
        return rejectWithValue(error.message)
    }
})

export const videos = createAsyncThunk('videos', async(params:any,{ rejectWithValue })=>{
    try {
        const communityVideosRes:any = await videoModel.videos(params)
        return communityVideosRes.data.data
    } catch (error:any) {
        if(error.errors){
            return rejectWithValue({isValidationError:true, validationMessage:error.errors[0] || 'An error occurred during validation'});
        }
        return rejectWithValue(error.message)
    }
})

export const {handleYtChannelID,handlePageLimit,handleSelect} = channelVideosSlice.actions
export const {handleFilters} = videosSlice.actions