import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/reducers'
import toastReducer from '../features/Toast/reducers'
import channelReducer from '../features/channel/reducers'
import videoReducer from '../features/video/reducers'
import userReducer from '../features/user/reducers'
import settingReducer from '../features/setting/reducers'
import filtersReducer from '../features/filters/reducers'


export const store = configureStore({
  reducer: {
    auth:authReducer,
    toast:toastReducer,
    channel:channelReducer,
    video:videoReducer,
    user:userReducer,
    setting:settingReducer,
    filters:filtersReducer,
  },
})

// const store = configureStore({
//   reducer: {
//     auth:authReducer,
//     toast:toastReducer
//   },
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
// })

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch