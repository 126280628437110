import React, { useEffect } from 'react'
import { useParams,useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hooks/hooks'
import { channelDetails } from '../features/channel/actions';
import ChannelDetails from '../components/ChannelDetails';
import UpdatedTime from '../components/UpdatedTime';
import { Global } from '../utils/global';
import Analytic from '../components/Analytic';
import AdvAnalyticsContainer from '../components/AdvAnalyticsContainer';
import LoadingFullWindow from '../components/Loaders/LoadingFullWindow';
import Back from '../components/Back';


const ChannelDetailsPage = () => {
  const { channelID } = useParams();
  const dispatch = useAppDispatch()
  const globalModel = new Global()
  const {data:chDetailsData,isLoading:isChannelDetailsLoading} = useAppSelector((state:any)=>state.channel.channelDetails)
  const [channelDetailsData] = chDetailsData?chDetailsData:''

  const anlyticsData = [
    {title:'subscribers',data: channelDetailsData && globalModel.getNumber(channelDetailsData.subscriber),toolTip:'Aggregate count of subscribers, data sourced from the Youtube public api'},
    {title:'views',data: channelDetailsData && globalModel.getNumber(channelDetailsData.views),toolTip:'The total video views coutn and its change in the last day views count, data sourced from the youtube public api.'},
    {title:'Avg Subscribers',data:channelDetailsData && globalModel.getNumber(channelDetailsData.subscriber/channelDetailsData.videos),toolTip:'Calculation: Subscribers divided by total videos using data from the youtube public api'},
    {title:'Videos',data:channelDetailsData && globalModel.getNumber(channelDetailsData.videos),toolTip:'Count of publicly avalilble video and its change in the last day videos count, data sourced from the youtube public api.'},
    {title:'Average Views',data:channelDetailsData && globalModel.getNumber(channelDetailsData.views/channelDetailsData.videos),toolTip:'Calculation: Total views divided by total videos using data from youtube public api.'},
    {title:'Category',data:channelDetailsData &&channelDetailsData.channelCategory, toolTip:'Data updated by user or drived from top video category on the channel.'},
    {title:'Channel Monetized',data:channelDetailsData && channelDetailsData.isChannelMonetize==='Y'?'Yes':'No', toolTip:'Data is updated by the user or support team when the channel meets youtube monetization criteria and display ads.'},
    {title:'Level',data:channelDetailsData &&channelDetailsData.level,toolTip:'The AskNaveen app has 6 levels categorized by totla subscribers: Level-1(0-1000), Level-2(1K-10K),Level-3(10K-100K),Level-4(100K-1M),Level-5(1M-10M) and Level-6(10M+)'},
  ]

  useEffect(()=>{
    if(channelID){
      dispatch(channelDetails(channelID))
    }
  },[])


  return (
    <div className='container pb-5' style={{maxWidth:'800px'}}>

        <div className='mb-5'>
          <Back/>
        </div>

        {channelDetailsData && 
        <ChannelDetails
        imageURL = {channelDetailsData.channelImage}
        userName={channelDetailsData.channelName}
        channelName={channelDetailsData.handle}
        channelID = {channelDetailsData.id}
        location = {`${channelDetailsData.city},${channelDetailsData.state},${channelDetailsData.country}`}
        />}

        <UpdatedTime
        updatedAt={`Updated: ${channelDetailsData && globalModel.getDateMonth(channelDetailsData.updateDateTime)}`}
        heading='Channel Analytics'
        />

        <div className='row mt-2'>
          {
          anlyticsData.map((value:any,index:number)=>{
          return(
          <Analytic
          title={value.title}
          data={value.data}
          toolTip={value.toolTip}
          />
          )
          })
          }
        </div>  

        <div className='row ps-2 mt-4'>
          <AdvAnalyticsContainer/>
        </div>

        {isChannelDetailsLoading?<LoadingFullWindow/>:null}
      </div>
  )
}

export default ChannelDetailsPage
