import { useNavigate } from "react-router-dom";

const useTokenUtils = () => {
    const navigate = useNavigate();
    const setTokenToLocalStorage = (token:string) => {
        localStorage.setItem('token', token);
    };
    const getTokenFromLocalStorage = () => {
        return localStorage.getItem('token');
    };
    const clearTokenFromLocalStorage = () => {
        const confirm = window.confirm("Do you want to logout?")
        if(!confirm){
            return
        }
        localStorage.removeItem('token');
        navigate("/login");
    };
    return { setTokenToLocalStorage, getTokenFromLocalStorage, clearTokenFromLocalStorage };
}

export default useTokenUtils;
  
