import React from 'react'

const LoadingFullWindow:React.FC = () => {
    const loaderOverlayStyle:any = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white overlay
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999, // Ensure it's above other elements
      };
    
      const loaderStyle:any = {
        border: '8px solid #f3f3f3', // Light grey
        borderTop: '8px solid #3498db', // Blue
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        animation: 'spin 1s linear infinite',
      };
    
      return (
        <div style={loaderOverlayStyle}>
          {/* <div style={loaderStyle}></div> */}
            <div className="spinner-border text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
      );
}

export default LoadingFullWindow
