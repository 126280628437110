import React, { useEffect } from 'react'
import Icons from '../../components/Icons'
import Video from '../../components/Video'
import TabBar from '../../components/TabBar'
import Search from '../../components/Search'
import Select from '../../components/Select'
import { useAppSelector, useAppDispatch } from '../../hooks/hooks'
import { settingValue } from '../../features/setting/actions'
import { videos } from '../../features/video/actions'
import { Global } from '../../utils/global'
import ScrollContainer from '../../components/ScrollContainer'
import LoadingFullWindow from '../../components/Loaders/LoadingFullWindow'
import { setCategories, setCountries, setSortBy, setTabItems } from '../../features/filters/actions'
import { sortByItems } from '../../utils/constants'

const Videos:React.FC = () => {
  const {EqualizerIcon,TrendingUpIcon} = Icons()
  const dispatch = useAppDispatch()
  const globalModel = new Global()
  const {data:settingValueData, isLoading:isSettingValueLoading} = useAppSelector((state:any)=>state.setting.settingValue)
  const {data:videosData,isLoading:isVideosDataLoading,filters:videosFilters,params:videosParams} = useAppSelector((state:any)=>state.video.videos)
  const {countries,categories,tabItems,sortBy} = useAppSelector((state:any)=>state.filters.filters)

  useEffect(()=>{
    // if(!settingValueData){
    //   dispatch(settingValue('channelCountry'))
    //   dispatch(settingValue('videoCategoryKey'))
    //   dispatch(settingValue('videoTypeKey'))
    // }
    dispatch(videos(videosParams))
  },[
    videosParams.pageLimit,
    videosParams.country,
    videosParams.videoCategoryId,
    videosParams.isOfficialMusic,
    videosParams.videoType,
    videosParams.sortBy
  ])

  useEffect(() => {
    if(!settingValueData){
      dispatch(settingValue('channelCountry'))
      dispatch(settingValue('videoCategoryKey'))
      dispatch(settingValue('videoTypeKey'))
    }
    if(settingValueData){
      if (settingValueData.countries) {
        dispatch(setCountries(settingValueData.countries));
      }
      if(settingValueData.categories){
        dispatch(setCategories(settingValueData.categories))
      }
      if(settingValueData.videoType){
        dispatch(setTabItems(settingValueData.videoType))
      }
      if(sortByItems){
        dispatch(setSortBy(sortByItems))
      }
    }
  }, [settingValueData]);

  return (
    <div className='container' style={{maxWidth:'800px'}}>
      {/* Tab bar */}
      <div className='row'>
        <TabBar
        tabData={tabItems && tabItems}
        type='videos'
        filterType='videoType'
        selected='Viral'
        />
      </div>
      {/*Select Filters  */}
      <div className='row mt-1 mb-4'>
        <div className='overflow-auto p-0'>
          <div className='d-flex justify-content-md-center ps-2'>
            <Search/>
            <Select
            selectData={countries && countries}
            type='videos'
            filterType='country'
            />
            <Select
            selectData={categories && categories}
            type='videos'
            filterType='category'
            selected='Category'
            />
            <Select
            selectData={sortBy && sortBy}
            type='videos'
            filterType='sortBy'
            />
          </div>
        </div>
      </div>
      {/* Videos Container */}
      <ScrollContainer type='videos'>
        <div className='row'>
          {
          videosData &&
          videosData.map((value:any,index:number)=>{
          return(
          <Video
          title={value.videoTitle}
          name={value.channelTitle}
          views={globalModel.getNumber(value.views)}
          dailyViews={`+${globalModel.getNumber(value.dailyViews)}`}
          likes={globalModel.getNumber(value.likes)}
          date={globalModel.getDateMonthYear(value.addDateTime)}
          imageURL={value.ytVideoId}
          duration={globalModel.getVideoDuration(value.duration)}
          trendingUpIcon={value.popular!=''?<TrendingUpIcon/>:''}
          equalizerIcon={<EqualizerIcon color='gray'/>}
          />
          )
          })
          }
        </div>
      </ScrollContainer>
      {/* Loader  */}
      {isVideosDataLoading?<LoadingFullWindow/>:null}
    </div>
  )
}

export default Videos
