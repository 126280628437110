import React from 'react'
import Icons from '../components/Icons'
interface CircleStyles {
  [key: string]: React.CSSProperties;
}

const ChannelDetails:React.FC <any> = ({userName,channelName,channelID,location,imageURL}:{userName:string,channelName:string,channelID?:number,location:string,imageURL:string}) => {
    const {LikeIcon,YouTubeIcon,Location,FavoriteIcon} = Icons()  
  return (
    // <div className='row bg-primary'>
    //     <div className='col-4 col-sm-3 col-md-2 mt-2 bg-danger' style={{position: 'relative'}}>
    //         <div style={{width: 120, height: 120 ,background: '#EFF1F2', borderRadius: 100, border: '1px rgba(0, 0, 0, 0.15) solid'}} />
    //         <div style={{width: 36, height: 36, left: 94  , top: 90, position: 'absolute', background: 'white', borderRadius: 9999, border: '1px rgba(0, 0, 0, 0.10) solid'}} />
    //         <div style={{width: 19, height: 16, left: 103, top: 96, position: 'absolute'}}><LikeIcon color='#FF0000'/></div>
    //     </div>
    //     <div className='col-8 col-sm-9 col-md-10 bg-secondary'>
    //         <div style={{color:'black',fontSize:20,fontWeight:600}}>{userName}</div>
    //         <div className='d-flex'>
    //           <div className='me-1' style={{color:'black',fontSize:20,fontWeight:600}}>
    //             {channelID}
    //           </div>
    //           <div style={{color: 'rgba(0, 0, 0, 0.50)',fontSize:15,fontFamily:'Roboto',fontWeight:400,marginTop:6}}>
    //             AskNaveen ID
    //           </div>
    //         </div>
    //         <div style={{color: 'rgba(0, 0, 0, 0.50)',fontSize:15,fontFamily:'Roboto',fontWeight:400}}>{channelName}</div>
    //         <div style={{color: 'rgba(0, 0, 0, 0.50)',fontSize:15,fontFamily:'Roboto',fontWeight:400}}><Location color='gray'/> {location}</div>
    //         <div style={{marginLeft:3}}><YouTubeIcon/></div>
    //     </div>
    // </div>


    <div className='overflow-auto'>
        <div className='d-flex'>
          <div className='mt-1' style={{minWidth:150}}>
            <div style={{position: 'relative'}}>
              {/* <div style={{width: 130, height: 130 ,background: '#EFF1F2', borderRadius: 100, border: '1px rgba(0, 0, 0, 0.15) solid'}} /> */}
              <img
                src={imageURL}
                // alt="Your Image Alt Text"
                style={{width: 130, height: 130 ,background: '#00AAFF ', borderRadius: 100, border: '1px rgba(0, 0, 0, 0.15) solid'}}
              />
              <div style={{width: 36, height: 36, left: 94  , top: 90, position: 'absolute', background: 'white', borderRadius: 9999, border: '1px rgba(0, 0, 0, 0.10) solid'}} />
              <div style={{width: 19, height: 16, left: 103, top: 96, position: 'absolute'}}><FavoriteIcon /></div>
            </div>
          </div>
          <div className=''>
            <div>
              <div style={{color:'black',fontSize:20,fontWeight:600}}>{userName}</div>
              <div className='d-flex'>
                <div className='me-1' style={{color:'black',fontSize:20,fontWeight:600}}>
                  {channelID}
                </div>
                <div style={{color: 'rgba(0, 0, 0, 0.50)',fontSize:15,fontFamily:'Roboto',fontWeight:400,marginTop:6}}>
                  AskNaveen ID
                </div>
              </div>
              <div style={{color: 'rgba(0, 0, 0, 0.50)',fontSize:15,fontFamily:'Roboto',fontWeight:400}}>{channelName}</div>
              <div style={{color: 'rgba(0, 0, 0, 0.50)',fontSize:15,fontFamily:'Roboto',fontWeight:400}}><Location color='gray'/> {location}</div>
              <div style={{marginLeft:3}}><YouTubeIcon/></div>
            </div>
          </div>
        </div>
    </div>

    
  

  )
}

export default ChannelDetails
