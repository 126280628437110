import React, { useEffect, useState } from 'react'
import asknaveen from '../../assets/logo/asknaveen.jpg'
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from '../../hooks/hooks'
import { handleInput,resetSendOtpError,resetSendOtp,sendOtp, verifyOtp, resetVerifyError, resetVerifyOtp, resetIsILogin } from '../../features/auth/actions';
import Toast from '../../components/Toast';
import { handleShowToast } from '../../features/Toast/actions';
import ErrorModal from '../../components/Modals/ErrorModal';
import useTokenUtils from '../../utils/tokenUtils';

const Login:React.FC = () => {

  const dispatch = useAppDispatch()
  const {setTokenToLocalStorage} = useTokenUtils()
  const navigate = useNavigate()
  const {data:sendOtpData,credentials,isLoading:isSendOtpLoading,isError:isSendOtpError,errorMessage:sendOtpErrMsg,isValidationError:isSendOtpValidationErr,validationMessage:sendOtpValidationMsg} = useAppSelector((state:any)=>state.auth.sendOtp)
  const {data:verifyOtpData,isLoading:isVerifyOtpLoading,isError:isVerifyOtpError,errorMessage:verifyOtpErrMsg,isValidationError:isVerifyOtpValidationErr,validationMessage:verifyOtpValidationMsg} = useAppSelector((state:any)=>state.auth.verifyOtp)
  // console.log(useAppSelector((state:any)=>state.auth.sendOtp))
  // console.log(useAppSelector((state:any)=>state.auth.verifyOtp))

  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  useEffect(() => {
    if(sendOtpData && sendOtpData.status==='failed'){
      return alert(sendOtpData.message)
    } 
    if (verifyOtpData && verifyOtpData.status === 'failure') {
      return alert(verifyOtpData.message);
    }
    if(verifyOtpData && verifyOtpData.status === 'success'){
      setTokenToLocalStorage(verifyOtpData.token)
      navigate("/dashboard");
      // toastModal.notifySuccess(verifyOtpData.message) // this not working
      dispatch(resetSendOtp())
      dispatch(resetVerifyOtp())
    } 
    dispatch(resetIsILogin())
    // dispath(resetIsILogin())
  }, [verifyOtpData,sendOtpData]);

  return (
    <div className='d-flex d-flex align-items-center justify-content-center' style={{height:'100vh'}}>
      <div className='container p-0'>
        <div className='pt-2 pb-2' style={{textAlign: 'center', color: 'black', fontSize: '20px'}}>
          <img  className='' src={asknaveen} alt="" />
          <div className='mb-5 mt-4' style={{ height: '22px', textAlign: 'center', color: 'black', fontSize: '20px', fontFamily: 'Roboto', fontWeight: 600, wordWrap: 'break-word' }}>Sign in to continue!</div>
          {
          (!sendOtpData || sendOtpData.status==='failed') &&
          <div>
            <input onChange={(e)=>{dispatch(handleInput({name:'email',value:e.target.value}))}} className='mt-5' type="email" id="email" name="email" style={{border: 'none',borderBottom:isSendOtpValidationErr?'1px solid red':'1px solid rgba(0, 0, 0, 0.15)',paddingTop:4,paddingBottom:4,fontSize: '16px',fontFamily: 'Roboto',fontWeight: 400,width: '80%',maxWidth:'400px',boxSizing: 'border-box',outline:'none'}} placeholder='Email address'/><br/>
            <div className='d-flex justify-content-center'>
              <div className='d-flex' style={{width: '80%',maxWidth:'400px',fontSize: '14px',fontFamily: 'Roboto',fontWeight: 400,color:'red',paddingTop:4,paddingBottom:4,}}>{sendOtpValidationMsg}</div>
            </div>
            <button onClick={()=>{dispatch(sendOtp(credentials.email))}} className='mt-4' style={{ width: '80%',maxWidth:'400px', height: '48px', left: '39px', top: '171px', background: '#FF0000', borderRadius: '10px',color:'white', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 600, border:'none' }}>{isSendOtpLoading?'...Loading':'Send OTP'}</button>
            <div className='mt-3'>
              <span style={{ color: 'rgba(0, 0, 0, 0.50)', fontSize: '14px', fontFamily: 'Roboto', fontWeight: 400}}>New to AskNaveen </span>
              <Link to='/register' style={{ color: '#0078E7', fontSize: '14px', fontFamily: 'Roboto', fontWeight: 400}}>Register here</Link>
            </div>
          </div>
          }

          {
          sendOtpData && sendOtpData.status=='success' &&
          <div className='m-0 p-0'>
            <div className='d-flex d-flex align-items-center justify-content-center'>
              <div className='mt-4 ms-3' style={{overflow:'auto',fontSize: '16px', fontFamily: 'Roboto', border:'none',width:'80%',maxWidth:'400px'}}>
                <div className='float-start text-muted'>Enter OTP send to your email</div><br/>
                <div className='float-start' style={{fontWeight:'600'}}>{credentials.email}</div>
              </div>
            </div>
            <input onChange={(e)=>{dispatch(handleInput({name:'otp',value:e.target.value}))}} className='mt-4' type="email" id="email" name="email" style={{border: 'none',borderBottom: '1px solid rgba(0, 0, 0, 0.15)',padding: '8px',fontSize: '16px',fontFamily: 'Roboto',fontWeight: 400,width: '80%',maxWidth:'400px',boxSizing: 'border-box',marginBottom: '20px',outline:'none'}} placeholder="Enter 6 digit OTP" /><br/>
            <div className='d-flex justify-content-center'>
              <div className='d-flex' style={{width: '80%',maxWidth:'400px',fontSize: '14px',fontFamily: 'Roboto',fontWeight: 400,color:'red',paddingTop:4,paddingBottom:4,}}>{verifyOtpValidationMsg}</div>
            </div>
            <button onClick={()=>{dispatch(verifyOtp({email:credentials.email,otp:credentials.otp}))}} className='mt-4' style={{ width: '80%',maxWidth:'400px', height: '48px', left: '39px', top: '171px', background: '#FF0000', borderRadius: '10px',color: 'white', fontSize: '16px', fontFamily: 'Roboto', fontWeight: 600, border:'none' }}>Submit</button>
            <div className='mt-3'>
              <label className='' style={{ color: 'rgba(0, 0, 0, 0.50)', fontSize: '14px', fontFamily: 'Roboto', fontWeight: 400}}>Resend OTP in <a style={{color:'red'}}>{timeLeft}</a></label> 
              <br/>
              <a  href='' style={{ color: '#0078E7', fontSize: '14px', fontFamily: 'Roboto', fontWeight: 400, textDecoration:'none'}}>Resend OTP</a>
            </div>
          </div>
          }
        </div>
      </div>
      {/* <ErrorModal isOpen={isSendOtpError || (verifyOtpData && verifyOtpData.status==='failure') } onClose={!isSendOtpError || !isVerifyOtpError}> */}
      <ErrorModal isOpen={isSendOtpError} onClose={!isSendOtpError}>
        <div style={{fontSize:16,fontWeight:600}}>Something went wrong...! Please try again.</div>
        <div style={{fontSize:14}}>{sendOtpErrMsg || (verifyOtpData && verifyOtpData.message)}</div>
        <div className='d-flex justify-content-center mt-3'>
          <button onClick={()=>{dispatch(resetSendOtpError());dispatch(resetVerifyError())}} style={{outline:'none',border:'none',paddingLeft:10,paddingRight:10,paddingBottom:4,paddingTop:2,color:'white',backgroundColor:'#FF0000',borderRadius:4}}>Okey</button> 
        </div>
      </ErrorModal>
    </div>
  )
}

export default Login



