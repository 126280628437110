import React, { useEffect } from 'react'
import Icons from '../../components/Icons'
import Video from '../../components/Video'
import Video1 from '../../components/Video1'
import TabBar from '../../components/TabBar'
import Search from '../../components/Search'
import Select from '../../components/Select'
import useAuthUtils from '../../utils/authUtils'

const videoData = [
  {title:'£trending $ ',name:'Inder Arya',views:'50.3M',dailyViews:'15.4M',likes:'1.7M',date:'6 Dec 2023',imageURL:'https://plus.unsplash.com/premium_photo-1687558246422-e94f0864d467?q=80&w=1365&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
  {title:'£trending $ inderaya £.......',name:'Inder Arya',views:'50.3M',dailyViews:'15.4M',likes:'1.7M',date:'6 Dec 2023',imageURL:'https://plus.unsplash.com/premium_photo-1687558246422-e94f0864d467?q=80&w=1365&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
  {title:'£trending $ inderaya £.......',name:'Inder Arya',views:'50.3M',dailyViews:'15.4M',likes:'1.7M',date:'6 Dec 2023',imageURL:'https://plus.unsplash.com/premium_photo-1687558246422-e94f0864d467?q=80&w=1365&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
  {title:'£trending $ inderaya £.......',name:'Inder Arya',views:'50.3M',dailyViews:'15.4M',likes:'1.7M',date:'6 Dec 2023',imageURL:'https://plus.unsplash.com/premium_photo-1687558246422-e94f0864d467?q=80&w=1365&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
  {title:'£trending $ inderaya £.......',name:'Inder Arya',views:'50.3M',dailyViews:'15.4M',likes:'1.7M',date:'6 Dec 2023',imageURL:'https://plus.unsplash.com/premium_photo-1687558246422-e94f0864d467?q=80&w=1365&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
]
const tabData = [
  {label:'Active', tabIndex:1},
  {label:'Closed', tabIndex:2},
  {label:'Result', tabIndex:3},
]
const countryData = [
  {key:'IN',value:'India'},
  {key:'GB',value:'Germemy'},
]
const categoryData = [
  {key:'1',value:'Film & Animation'},
  {key:'2',value:'Auto & Vehicle'},
]
const filterData = [
  {key:'latest',value:'latest'},
  {key:'popular',value:'popular'},
]

const Community:React.FC = () => {
  const {EqualizerIcon,ThumbsUpIcon,PlayIcon,FavoriteIcon,CheckIcon} = Icons()
  // const {checkAndDispatchIsILogin} = useAuthUtils()
  // useEffect(() => {
  //   checkAndDispatchIsILogin()
  // }, []);
  return (
    <div  className='container pb-5 mt-1' style={{maxWidth:'800px'}}>
      <div className='row'>
        <TabBar
        tabData={tabData}
        />
      </div>
      <div className='row mt-1 mb-4'>
        <div className='overflow-auto p-0'>
          <div className='d-flex justify-content-md-center ps-2'>
            <Search/>
            <Select
            selectData={countryData}
            />
            <Select
            selectData={categoryData}
            />
            <Select
            selectData={filterData}
            />
          </div>
        </div>
      </div>
      <div className='row'>
      {
      videoData.map((value,index)=>{
      return(
      <Video1
      title={value.title}
      name={value.name}
      views={value.views}
      dailyViews={value.dailyViews}
      likes={value.likes}
      date={value.date}
      imageURL={value.imageURL}
      isOpinion={true}
      />
      )
      })
      }
      </div>      
    </div>
  )
}

export default Community
