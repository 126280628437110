import React, { useEffect } from 'react'
import Icons from '../../components/Icons'
import MoreItem from '../../components/MoreItem'
import useAuthUtils from '../../utils/authUtils'

const More:React.FC = () => {
  // const {checkAndDispatchIsILogin} = useAuthUtils();
  const {PaperPlaneIcon,ArrowRightIcon,ListIcon,MusicIcon,IdCardIcon,IdBadgeIcon,TrophyIcon,AddressCardIcon,CreditCardIcon,TrashCanIcon,SignOutIcon} = Icons()
  const MoreData = [
    {title:'Add Channel / Video', icon:PaperPlaneIcon, arrow:ArrowRightIcon, type:'addChannel',},
    {title:'My Added Shorts', icon:ListIcon, arrow:ArrowRightIcon , type:'addedShorts'},
    {title:'Sound Effects', icon:MusicIcon, arrow:ArrowRightIcon , type:'soundEffect' },
    {title:'Update Profile', icon:IdCardIcon, arrow:ArrowRightIcon, type:'updateProfile' },
    {title:'Leaderboard', icon:TrophyIcon, arrow:ArrowRightIcon, type:'leaderboard'},
    {title:'About', icon:IdBadgeIcon, arrow:ArrowRightIcon, type:'about'},
    {title:'Contact Us', icon:AddressCardIcon, arrow:ArrowRightIcon, type:'countactUs'},
    {title:'Membership', icon:CreditCardIcon, arrow:ArrowRightIcon, type:'membership'},
    {title:'Delete Account', icon:TrashCanIcon, arrow:ArrowRightIcon, type:'delete'},
    {title:'Sign out', icon:SignOutIcon, arrow:ArrowRightIcon, type:'signOut'},
  ]
  
  // useEffect(() => {
  //   checkAndDispatchIsILogin()
  // }, []);
  return (
    <div  className='container pb-5 mt-1' style={{maxWidth:'800px'}}>
      <div className='ps-4 pe-4'>
        <div className='row'>
          <div className='col-12 mb-2 p-0'>More Pages</div>
          <div className='row m-0' style={{backgroundColor:"rgba(0, 0, 0, 0.02)",borderRadius:8}}>
          {
          MoreData.map((value)=>{
          return(
          <MoreItem
          title={value.title}
          icon={value.icon}
          arrow={value.arrow}
          type={value.type}
          />
          )
          })
          }
          </div>
        </div>      
      </div>
    </div>
  )
}

export default More
