import React from 'react'

const Opinion:React.FC <any> = () => {
  return (
    <div className='col-12 col-sm-12 mb-1 p-0 mt-1 mb-4'>
        <div className='mb-1' style={{width: '100%', height: 0, left: -0, top: 55.66, border: '1px rgba(0, 0, 0, 0.05) solid'}}></div>
        <div className='d-flex justify-content-between'>
        <div className='d-flex' style={{fontSize:14,fontWeight:600}}>
            <div className='text-muted'>Target:</div>
            <div className='ms-1'>200K</div>
        </div>
        <div className='d-flex text-muted' style={{fontSize:14,fontWeight:600}}>
            <div className='me-2'>Your Opinion</div>
            <input type='checkbox'/> <label className='me-2'>Yes</label>
            <input type='checkbox'/> <label>No</label>
        </div>
        </div>
        <div className='mt-1' style={{width: '100%', height: 0, left: -0, top: 55.66, border: '1px rgba(0, 0, 0, 0.05) solid'}}></div>
  </div>
  )
}

export default Opinion
