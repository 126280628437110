import React from 'react'
import AdvAnalytics from './AdvAnalytics'
import Icons from './Icons'

const AdvAnalyticsContainer:React.FC = () => {
  const {EqualizerIcon,ArrowRightIcon,EqualizerAnalyticsIcon} = Icons();
  const advAnalyticsData = [
    {icon:<EqualizerAnalyticsIcon color='#756BFF'/>,title:'Channel Daily Performance',arrow:ArrowRightIcon,type:'performance'},
    {icon:<EqualizerAnalyticsIcon color='#756BFF'/>,title:'Channel Videos',arrow:ArrowRightIcon,type:'videos'},
  ]
  return (
    <div className='col-12 col-sm-6 p-0 ps-1 pe-2 pe-sm-2'>
      <div className='mb-2' style={{fontWeight:600}}>Advanced Analytics</div>
      <div style={{border:'1px solid rgba(0,0,0,0.2)',borderRadius:8}}>
        {
        advAnalyticsData.map((value:any,index:number)=>{
        return(
        <AdvAnalytics
        icon = {value.icon}
        title={value.title}
        arrow={value.arrow}
        type={value.type}
        />
        )
        })
        }
      </div>
    </div>
  )
}

export default AdvAnalyticsContainer
