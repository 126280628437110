import React, { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../hooks/hooks'
import { settingValue } from '../features/setting/actions'
import LoadingFullWindow from './Loaders/LoadingFullWindow'


const MembershipBenefits = () => {
    const dispatch = useAppDispatch()
    const {data:settingValueData, isLoading:isSettingValueLoading} = useAppSelector((state:any)=>state.setting.settingValue)

    useEffect(()=>{
        dispatch(settingValue('proBenefitsJson'))
    },[])
  return (
    <div className='col-12 col-sm-6 p-0 pe-2 pe-sm-3 mb-4'>
        {
        settingValueData && 
        settingValueData.map((value:string,index:number)=>{
        return(
        <>
        {index===0?<div className='mb-2' style={{fontWeight:600}}>Pro Membership Benefits</div>:null}
        <div className='d-flex'>
            <div style={{color:'purple',fontSize:20}}>{'\u2611'}</div>
            <div className='ms-2' style={{fontSize:14}}>{value}</div>
        </div>
        </>
        )
        })
        }
        {isSettingValueLoading?<LoadingFullWindow/>:null}
    </div>
  )
}

export default MembershipBenefits
