import React, { useState } from 'react'
import Icons from '../components/Icons'
import { useNavigate } from 'react-router-dom';

const Navbar:React.FC = () => {
    const {DashboardIcon,VideoIcon,ChannelIcon,CommunityIcon,MoreIcon} = Icons()
    const navigate = useNavigate()
    const [color, setColor] = useState({
        color:'gray',
        type:'dashboard'
    })
    const handleNavigate = ({ type, to }: { type: string; to: string }) => {
        const newColor = '#FF0000';
        setColor({
          ...color,
          color: newColor,
          type,
        });
        navigate(to);
    };
  return (
    <div>
        {/* <div className='fixed-bottom  overflow-auto' style={{position:'absolute',bottom:0}}> */}
        <div className='fixed-bottom  overflow-auto' style={{bottom:0,backgroundColor:'white'}}>
            <div className='' style={{width: '100%', height: 0, left: -0, top: 55.66, border: '1px rgba(0, 0, 0, 0.05) solid'}}></div>
            <div className='d-flex justify-content-center'>
                <div className='overflow-auto p-1 d-flex justify-content-between' style={{width:'500px',fontSize:'14px',fontFamily:'Roboto',fontWeight:'500'}}>
                    <div onClick={()=>{handleNavigate({type:'dashboard',to:'/dashboard'})}} className='text-center me-3 '>
                        <DashboardIcon color={color.type==='dashboard'?color.color:'gray'}/>
                        <div style={{marginTop:'2px',color:color.type==='dashboard'?color.color:'gray'}}>DashBoard</div>
                    </div>
                    <div onClick={()=>{handleNavigate({type:'videos',to:'/videos'})}} className='text-center me-3'>
                        <VideoIcon color={color.type==='videos'?color.color:'gray'}/>
                        <div style={{color:color.type==='videos'?color.color:'gray'}}>videos</div>
                    </div>
                    <div onClick={()=>{handleNavigate({type:'channels',to:'/channels'})}}  className='text-center me-3' >
                        <ChannelIcon color={color.type==='channels'?color.color:'gray'} />
                        <div style={{color:color.type==='channels'?color.color:'gray'}}>Channels</div>
                    </div>
                    <div onClick={()=>{handleNavigate({type:'community',to:'/community'})}} className='text-center me-3' >
                        <CommunityIcon color={color.type==='community'?color.color:'gray'} />
                        <div style={{color:color.type==='community'?color.color:'gray'}}>Community</div>
                    </div>
                    <div onClick={()=>{handleNavigate({type:'more',to:'/more'})}} className='text-center'>
                        <MoreIcon color={color.type==='more'?color.color:'gray'} />
                        <div style={{color:color.type==='more'?color.color:'gray'}}>More</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Navbar
