import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { sendOtp,verifyOtp,getIsILogin } from './actions'


export interface ISendOtpToEmailState{
    data:[] | null,
    isLoading:boolean,
    isError:boolean,
    errorMessage:any | null |unknown
    credentials:{
        email:string,
        otp:number
    }
}
export const sendOtpSlice = createSlice({
    name:'sendOtp',
    initialState:{
        data:null,
        isLoading:false,
        isError:false,
        errorMessage:null,
        isValidationError:false,
        validationMessage:null,
        credentials:{
          email:'',
          otp:0
        }
    },
    extraReducers:(builder) => {
      builder.addCase(sendOtp.pending, (state, action)=>{
        state.isLoading = true;
      })
      builder.addCase(sendOtp.fulfilled, (state, action)=>{
          state.isLoading = false;
          state.data = action.payload;
      })
      builder.addCase(sendOtp.rejected, (state:any, action:any)=>{
          if(action.payload.isValidationError){
            state.isLoading = false;
            state.isValidationError = true
            state.validationMessage = action.payload.validationMessage
          }else{
            console.log("error", action.payload, action.error.message || 'An error occurred');
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload
          }
      })
    },
    reducers:{
      handleInput:(state:any,action)=>{
          const name = action.payload.name
          const value = action.payload.value
          state.credentials[name] = value
      },
      resetSendOtp:(state)=>{
        state.data = null;
        state.isLoading = false;
        state.isError = false;
        state.isValidationError = false;
        state.errorMessage = null;
        state.validationMessage = null;
        state.credentials = {
          email: '',
          otp: 0,
        }
      },
      resetSendOtpError:(state)=>{
        state.data = null;
        state.isLoading = false;
        state.isError = false;
        state.isValidationError = false;
        state.errorMessage = null;
        state.validationMessage = null;
      }
    }
})


export const verifyOtpSlice = createSlice({
  name:'verifyOtp',
  initialState:{
      data:null,
      isLoading:false,
      isError:false,
      errorMessage:null,
      isValidationError:false,
      validationMessage:null,
  },
  extraReducers:(builder) => {
    builder.addCase(verifyOtp.pending, (state, action)=>{
      state.isLoading = true;
    })
    builder.addCase(verifyOtp.fulfilled, (state, action)=>{
        state.isLoading = false;
        state.data = action.payload;
    })
    builder.addCase(verifyOtp.rejected, (state:any, action:any)=>{
        if(action.payload.isValidationError){
          state.isLoading = false;
          state.isValidationError = true
          state.validationMessage = action.payload.validationMessage
        }else{
          console.log("error", action.payload, action.error.message || 'An error occurred');
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload
        }
    })
  },
  reducers:{
    handleInput:(state:any,action)=>{
        const name = action.payload.name
        const value = action.payload.value
        state.credentials[name] = value
    },
    resetVerifyOtp:(state)=>{
      state.data = null;
      state.isLoading = false;
      state.isError = false;
      state.isValidationError = false;
      state.errorMessage = null;
      state.validationMessage = null;
    },
    resetVerifyError:(state)=>{
      state.data = null;
      state.isLoading = false;
      state.isError = false;
      state.isValidationError = false;
      state.errorMessage = null;
      state.validationMessage = null;
    }
  }
})

export const getIsILoginSlice = createSlice({
  name:'getIsILogin',
  initialState:{
      isLoading:true,
      isError:false,
      isILogin:false,
      ILoginMessage:null
  },
  extraReducers:(builder)=>{
      builder.addCase(getIsILogin.pending, (state, action)=>{
          state.isLoading = true;
          state.isILogin = false
      })
      builder.addCase(getIsILogin.fulfilled, (state, action)=>{
          state.isLoading = false;
          if(action.payload){
              const {userType} = action.payload
              if(userType === 'A' || userType === 'U'){
                  state.isILogin = true
              }
              state.ILoginMessage = action.payload.message
          }else{
              state.isILogin = false
          }
      })
      builder.addCase(getIsILogin.rejected, (state, action)=>{
          console.log("error", action.error);
          state.isError = true;   
          state.isILogin = false
      })
  },
  reducers:{
      resetIsILogin: (state) =>{
          state.isLoading = false
          state.isError = false
          state.isILogin = true
          state.ILoginMessage = null
      }
  },
})