import React from 'react'
import { useAppSelector, useAppDispatch } from '../../hooks/hooks'
import { Global } from '../../utils/global'
import LoadingFullWindow from '../Loaders/LoadingFullWindow';

const PerformanceTable:React.FC = () => {
  const globalModel = new Global();
  const {data:channelHisData,isLoading:isChannelHisLoading} = useAppSelector((state:any)=>state.channel.channelHistory)
  // console.log(channelHisData)

  return (
    <div className='row mt-2'>
    <div className='mb-2 ps-0' style={{fontWeight:600}}>Last 30 Days</div>
    <table className="table">
        <thead>
            <tr>
            <th scope="col">Date</th>
            <th scope="col">Views</th>
            <th scope="col">Subscribers</th>
            <th scope="col">Videos</th>
            </tr>
        </thead>
        <tbody>
          {channelHisData &&
          channelHisData.map((value:any,index:number)=>{
            return(
            <tr>
              <td>{globalModel.getDateMonth(value.addDateTime)}</td>
              <td><div className='d-flex'><div>{globalModel.getNumber(value.views)}</div><div style={{color:'green'}}>+{globalModel.getNumber(value.dailyViews)}</div></div></td>
              <td><div className='d-flex'><div>{globalModel.getNumber(value.subscribers)}</div><div style={{color:'green'}}>+{globalModel.getNumber(value.dailySubscribers)}</div></div></td>
              <td><div className='d-flex'><div>{globalModel.getNumber(value.videos)}</div><div style={{color:'green'}}>+{globalModel.getNumber(value.dailyVideos)}</div></div></td>
            </tr>
            )
          })}
        </tbody>
    </table>
    {isChannelHisLoading?<LoadingFullWindow/>:null}
    {/* <LoadingFullWindow/> */}
    </div>
  )
}

export default PerformanceTable
