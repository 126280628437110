import React from 'react'
import { Link } from 'react-router-dom';

const Error404:React.FC = () => {
  return (
    <div>
      {/* <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
    <a className="navbar-brand" href="#">Your Brand</a>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item active">
          <a className="nav-link" href="#">Home</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Features</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Contact</a>
        </li>
      </ul>
    </div>
        </nav>

        <div className="jumbotron text-center">
            <h1 className="display-4">Welcome to Your Website</h1>
            <p className="lead">A beautiful and responsive page created with Bootstrap.</p>
        </div>

        <div className="container">
            <div className="row">
            <div className="col-lg-4">
                <div className="card mb-4">
                <img src="https://via.placeholder.com/300" className="card-img-top" alt="Feature Image" />
                <div className="card-body">
                    <h5 className="card-title">Feature 1</h5>
                    <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="card mb-4">
                <img src="https://via.placeholder.com/300" className="card-img-top" alt="Feature Image" />
                <div className="card-body">
                    <h5 className="card-title">Feature 2</h5>
                    <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
                </div>
            </div> 
            <div className="col-lg-4">
                <div className="card mb-4">
                <img src="https://via.placeholder.com/300" className="card-img-top" alt="Feature Image" />
                <div className="card-body">
                    <h5 className="card-title">Feature 3</h5>
                    <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
                </div>
            </div>
            </div>
        </div>
        
        <footer className="bg-dark text-white text-center py-3">
            &copy; 2023 Your Website. All rights reserved.
        </footer> */}

    <div>
      <div className="container d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <h1 className="display-1">404</h1>
        <p className="lead">Page Not Found</p>
        <p>Oops! The page you are looking for might be in another castle.</p>
        <Link to="/dashboard" className="btn btn-primary">
          Go back to Dashboard
        </Link>
      </div>
    </div>
    </div>
    </div>
  )
}

export default Error404
