import React from 'react'
import Icons from './Icons'
import { useNavigate } from 'react-router-dom'

const Channel:React.FC <any> = ({type,id,channelName,subscribers,views,dailyViews,imageURL,favouriteIcon}:{type:string,id:number,channelName:string,subscribers:string,views:string,dailyViews:string,imageURL:string,favouriteIcon:any}) => {
    const {FavoriteIcon,CheckIcon} = Icons()
    const navigate = useNavigate()

    const handleClick = () => {
        if(type==='channels'){
            navigate(`/channel/${id}`)
        }
    }

  return (
    <div onClick={handleClick} className='col-12 col-sm-6 col-md-4 mb-3 p-0'>
        <div className='d-flex ps-3 pe-3 ps-sm-5 pe-sm-5 ps-md-4 pe-md-4'>
            {/* <div style={{ width: 80, height: 60,  background: '#EFF1F2', borderRadius: 9999 }}></div> */}
            <img  style={{ width: 80, height: 60,  background: '#EFF1F2', borderRadius: 9999 }} src={imageURL} />
            <div className='ms-2 d-flex justify-content-between' style={{width:'100%',color: 'rgba(0, 0, 0, 0.50)',justifyContent:'center'}}>
                <div className='m-0 p-0'>
                    <div style={{color:'black', fontSize:15, fontWeight:500}}>{channelName} <CheckIcon/></div>
                    <div style={{fontSize:13,fontFamily:'Roboto',fontWeight:400}}>{subscribers} subscribers</div>
                    <div style={{fontSize:13,fontFamily:'Roboto',fontWeight:400}} className='d-flex'><div>{views}views</div> <div className='ms-1 fw-bold' style={{color:'green'}}>{dailyViews}</div></div>
                </div>
                <div className='d-flex d-flex align-items-center'>
                {favouriteIcon}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Channel
