import React from 'react'
import Icons from '../components/Icons'

const UpdatedTime:React.FC<any> = ({updatedAt,heading}:{updatedAt:string,heading:string}) => {
  const {HelpIcon} = Icons()
  return (
    <div className='row mt-3'>
      <div className='col-6'>
        <div style={{color:'black',fontSize:'16px',fontWeight:600}}>{heading} <HelpIcon color='gray'/></div>
      </div>
      <div className='col-6'>
        <div className='float-end' style={{color:'rgba(0, 0, 0, 0.50)',fontSize:'14px',fontWeight:400}}>{updatedAt}</div>
      </div>
    </div>
  )
}

export default UpdatedTime
