import React from 'react'
import { useNavigate } from 'react-router-dom'
import useTokenUtils from '../utils/tokenUtils'

const MoreItem:React.FC <any>= ({title,icon,arrow,type}:{title:string,icon:any,arrow:any,type:string}) => {
  const navigate = useNavigate()
  const {clearTokenFromLocalStorage} = useTokenUtils()

  const handleClick = () => {
    switch(type){
      case 'membership':
        navigate('/membership');
        break;
      case 'signOut':
        clearTokenFromLocalStorage()
        break;
      default:
        break;
    }
  }

  return (
    <div className='col-12 col-sm-6 mb-2 mt-2 ps-sm-5 pe-sm-5 p-0' onClick={handleClick}>
        <div className='d-flex justify-content-between'>
        <div className='d-flex'>
            <div>{icon()}</div>
            <div className='ms-2 mt-1' style={{fontSize:14,fontFamily:'Roboto'}}>{title}</div>
        </div>
        <div>
            {arrow()}
        </div>
        </div>
    </div>
  )
}
  
export default MoreItem
