import { sendOtpSchema,verifyOtpSchema } from "../../schema/schema"
import { getIsILoginSlice, sendOtpSlice, verifyOtpSlice } from "./slices"
import {createAsyncThunk} from '@reduxjs/toolkit'
import { authentication } from "../../api/authentication"
const authenticationModel = new authentication()


export const sendOtp = createAsyncThunk('sendOtp', async(email:string,{ rejectWithValue })=>{
    try {
        const inputData = {email:email}
        await sendOtpSchema.validate(inputData, { abortEarly: false })
        const sendOtpRes:any = await authenticationModel.sendOtp(inputData.email)
        return sendOtpRes.data
    } catch (error:any) {
        if(error.errors){
            // return rejectWithValue(error.errors[0] || 'An error occurred during validation');
            return rejectWithValue({isValidationError:true, validationMessage:error.errors[0] || 'An error occurred during validation'});
        }
        return rejectWithValue(error.message)
    }
})

export const verifyOtp = createAsyncThunk('verifyOtp', async ({email,otp}:{email:string,otp:number},{rejectWithValue})=>{
    try {
        const inputData = {email:email,otp:otp}
        await verifyOtpSchema.validate(inputData, { abortEarly: false })
        const verifyOtpRes:any = await authenticationModel.verifyOtp(inputData.email,inputData.otp)
        return verifyOtpRes.data
        console.log(verifyOtpRes.data)
    } catch (error:any) {
        if(error.errors){
            return rejectWithValue({isValidationError:true, validationMessage:error.errors[0] || 'An error occurred during validation'});
        }
        return rejectWithValue(error.message)
    }
})

export const getIsILogin = createAsyncThunk('getIsILogin', async (token:any,{rejectWithValue})=>{
    try {
        const getIsILoginRes:any = await authenticationModel.getIsILogin(token)
        // if(getIsILoginRes.message){
        //     return getIsILoginRes
        // }
        return getIsILoginRes.data.data
    } catch (error:any) {
        if(error.errors){
            return rejectWithValue({isValidationError:true, validationMessage:error.errors[0] || 'An error occurred during validation'});
        }
        return rejectWithValue(error.message)
    }
})

export const { handleInput,resetSendOtp,resetSendOtpError } = sendOtpSlice.actions
export const { resetVerifyOtp, resetVerifyError } = verifyOtpSlice.actions
export const { resetIsILogin } = getIsILoginSlice.actions


