import React, { useEffect, useState } from 'react'
import Icons from './Icons';
import { useAppSelector, useAppDispatch } from '../hooks/hooks'
import { Global } from '../utils/global';
import { Navigate, useNavigate } from 'react-router-dom';
import { userDetails } from '../features/user/actions';
import { myChannels } from '../features/channel/actions';
import useTokenUtils from '../utils/tokenUtils';


const SideBar:React.FC <any> = ({isSidebarOpen,toggleSidebar}:{isSidebarOpen:boolean,toggleSidebar:any}) => {
  const {CheckIconNew,CircleImage} = Icons()
  const dispatch = useAppDispatch()
  const globalModel = new Global()
  const navigete = useNavigate()
  const {getTokenFromLocalStorage} = useTokenUtils()

  const {data:myChannelsData} = useAppSelector((state:any)=>state.channel.myChannels) 
  const {data:userDetailsData,isLoading:isUserDetailsLoading} = useAppSelector((state:any)=>state.user.userDetails)
  const myChannelFirst = myChannelsData?myChannelsData[0]:''

  // useEffect(() => {
  //   dispatch(myChannels(getTokenFromLocalStorage()))
  //   dispatch(userDetails(getTokenFromLocalStorage()))
  // }, []);



  const overlayStyle:any = {
    display: isSidebarOpen ? 'block' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom:0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 2000,
  };

  const sidebarStyle:any = {
    position: 'fixed',
    top: 0,
    right: isSidebarOpen ? 0 : '-280px',
    width: '280px',
    height: '100%',
    background: 'white',
    color: 'black',
    transition: 'right 0.3s ease-in-out',
    zIndex: 2000,
  };

  const contentStyle:any = {
    marginRight: isSidebarOpen ? '280px' : '0',
    transition: 'margin-right 0.3s ease-in-out',
  };

  return (
    <div onClick={()=>{toggleSidebar()}}>
      <div style={overlayStyle}></div>
      <div style={sidebarStyle}>
        <div className='container'>
            <div className='row ps-2' style={{height:70}}>
                <div className='d-flex align-items-center p-0'>
                    <div style={{width: 45, height: 45 ,background: 'purple', borderRadius:999, border: '1px rgba(0, 0, 0, 0.15) solid'}}></div>
                    <div className='ms-2'>
                        <div style={{fontSize:14,fontWeight:600}}>{userDetailsData && userDetailsData.name}</div>
                        <div style={{fontSize:13,fontFamily:'Roboto'}}>{ userDetailsData && userDetailsData.email}</div>
                    </div>
                </div>
            </div>

            <hr className='p-0 m-0 mt-2 mb-2'/>

            <div className='row ps-2' style={{fontSize:15,fontWeight:600}}>Your Channels</div>
            {
            myChannelsData &&
            myChannelsData.map((value:any,index:number)=>{
              return(
                <div onClick={()=>{navigete(`/dashboard/${value.id}`)}} className='row ps-2' style={{height:70}}>
                    <div className='d-flex align-items-center p-0'>
                        {/* <div style={{width: 45, height: 45 ,background: 'purple', borderRadius:999, border: '1px rgba(0, 0, 0, 0.15) solid'}}></div> */}
                        <img src={value.channelImage} style={{width: 45, height: 45 ,background: 'purple', borderRadius:999, border: '1px rgba(0, 0, 0, 0.15) solid'}} aria-hidden="true"/>
                        {/* <CircleImage imageURL={value.channelImage} /> */}
                        <div className='col-9 d-flex justify-content-between ms-2'>
                            <div>
                                <div style={{fontSize:14,fontWeight:600}}>{value.channelName}</div>
                                <div style={{fontSize:13,fontFamily:'Roboto'}}>{value.handle}</div>
                                <div style={{fontSize:13,fontFamily:'Roboto'}}>{globalModel.getNumber(value.subscriber)} subscribers</div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <CheckIconNew/>
                            </div>
                        </div>
                    </div>
                </div>
              )
            })
            }
        </div>
        {/* <div className='bg-primary'>
            <div className="" style={{height:'100vh'}}>
                <div className="position-absolute top-0 start-0">one</div>
                <div className="position-absolute top-0 start-50 translate-middle-x">two</div>
                <div className="position-absolute top-0 end-0">three</div>
                <div className="position-absolute top-50 start-0 translate-middle-y">four</div>
                <div className="position-absolute top-50 start-50 translate-middle">five</div>
                <div className="position-absolute top-50 end-0 translate-middle-y">six</div>
                <div className="position-absolute bottom-0 start-0">seven</div>
                <div className="position-absolute bottom-0 start-50 translate-middle-x">eiath</div>
                <div className="position-absolute bottom-0 end-0">nine</div>
            </div>
        </div> */}
      </div>
      {/* <div style={contentStyle}>
        <p>Main Content</p>
      </div> */}
    </div>
  );
};

export default SideBar
