import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from '../hooks/hooks'
import { handleFilters as handleVideosFilters } from '../features/video/actions';
import { handleFilters as channelsFilters } from '../features/channel/actions';

interface TabItemProps {
  label: string;
  tabSelected: any;
  isActive: any;
  onClick: (tabSelected: any) => void;
}

const TabItem: React.FC<TabItemProps> = ({ label, tabSelected, isActive, onClick }) => {
  return (
    <div className='p-2' style={{margin: '0 10px',cursor: 'pointer',borderBottom: isActive ? '3px solid #007bff' : '2px solid transparent',color: isActive ? '#007bff' : 'rgba(0, 0, 0, 0.50)',}} onClick={() => onClick(tabSelected)}>
      {label}
    </div>
  );
};


const TabBar: React.FC  <any> = ({tabData,type,filterType,selected}:{tabData:any,type:string,filterType:string,selected:string}) => {
  const dispatch = useAppDispatch()
  const [activeTab, setActiveTab] = useState<string>(selected);
  const handleTabClick = (tabSelected: any) => {
    setActiveTab(tabSelected);
    if(type==='videos'){
      dispatch(handleVideosFilters({type:filterType,value:tabSelected}))
    }
    if(type==='channels'){
      dispatch(channelsFilters({type:filterType,value:tabSelected}))
    }
  };

  return (
    <div className='p-0 overflow-auto mb-2'>
      {/* <div className='d-flex justify-content-md-center' style={{color:'rgba(0, 0, 0, 0.50)',fontSize:14,fontWeight:600}}> */}
      <div className='d-flex justify-content-between  justify-content-sm-center' style={{color:'rgba(0, 0, 0, 0.50)',fontSize:14,fontWeight:600}}>
      {
      tabData &&
      tabData.map((value:any)=>{
      return(
      <TabItem 
      label={value.label} 
      tabSelected={value.value } 
      isActive={activeTab === value.value} 
      onClick={handleTabClick} 
      />
      )
      })
      }
      </div>
      <div className='' style={{width: '100%', height: 0, left: -0, top: 55.66, border: '1px rgba(0, 0, 0, 0.04) solid'}}></div>
    </div>
  );
};

export default TabBar;










// const TabBar: React.FC  <any> = () => {
//   const [activeTab, setActiveTab] = useState<number>(1);
//   const handleTabClick = (tabSelected: number) => {
//     setActiveTab(tabSelected);
//   };
//   return (
//     <div className='p-0 overflow-auto mb-2'>
//         <div className='d-flex justify-content-md-center' style={{color:'rgba(0, 0, 0, 0.50)',fontSize:14,fontWeight:600}}>
//             <TabItem label="Viral" tabSelected={1} isActive={activeTab === 1} onClick={handleTabClick} />
//             <TabItem label="100M" tabSelected={2} isActive={activeTab === 2} onClick={handleTabClick} />
//             <TabItem label="Trending" tabSelected={3} isActive={activeTab === 3} onClick={handleTabClick} />
//             <TabItem label="Meet" tabSelected={4} isActive={activeTab === 4} onClick={handleTabClick} />
//             <TabItem label="Editing" tabSelected={5} isActive={activeTab === 5} onClick={handleTabClick} />
//         </div>
//         <div className='' style={{width: '100%', height: 0, left: -0, top: 55.66, border: '1px rgba(0, 0, 0, 0.04) solid'}}></div>
//     </div>
//   );
// };



