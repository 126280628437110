import React from 'react'
const User:React.FC = () => {
  return (
    <div>
        <div style={{width: '100%', height: '100%', position: 'relative', background: 'white'}}>
            <div style={{width: 428, height: 62, left: 0, top: 1038, position: 'absolute'}}>
                <div style={{width: 428, height: 62, left: 0, top: 0, position: 'absolute'}}>
                    <div style={{width: 428, height: 62, left: 0, top: 0, position: 'absolute', background: 'white', boxShadow: '0px -0.5px 0px rgba(0, 0, 0, 0.30)', backdropFilter: 'blur(27.18px)'}} />
                    <div style={{width: 65, left: 348, top: 36, position: 'absolute', textAlign: 'center', color: 'rgba(0, 0, 0, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '500', lineHeight: 22, wordWrap: 'break-word'}}>Revenue</div>
                    <div style={{width: 36, height: 36, left: 280, top: 5, position: 'absolute'}}>
                        <div style={{width: 22, height: 22, left: 8, top: 8, position: 'absolute', background: 'rgba(0, 0, 0, 0.50)'}}></div>
                    </div>
                    <div style={{width: 83, left: 256, top: 36, position: 'absolute', textAlign: 'center', color: 'rgba(0, 0, 0, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '500', lineHeight: 22, wordWrap: 'break-word'}}>Community</div>
                    <div style={{width: 36, height: 36, left: 114, top: 6, position: 'absolute'}} />
                    <div style={{width: 79, left: 98, top: 36, position: 'absolute', textAlign: 'center', color: 'rgba(0, 0, 0, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '500', lineHeight: 22, wordWrap: 'break-word'}}>Content</div>
                    <div style={{width: 22, height: 22, left: 127, top: 13, position: 'absolute', background: 'rgba(0, 0, 0, 0.50)'}}></div>
                    <div style={{width: 96, height: 96, left: 75, top: -26, position: 'absolute'}}></div>
                    <div style={{width: 90, left: 2, top: 36, position: 'absolute', textAlign: 'center', color: '#FF0000', fontSize: 13, fontFamily: 'Roboto', fontWeight: '500', lineHeight: 22, wordWrap: 'break-word'}}>Dashboard</div>
                    <div style={{width: 20, height: 20, left: 35, top: 12, position: 'absolute', background: '#FF0000'}}></div>
                    <div style={{width: 12, height: 22, left: 376, top: 13, position: 'absolute', background: 'rgba(0, 0, 0, 0.50)'}}></div>
                </div>
                <div style={{width: 65, left: 183, top: 36, position: 'absolute', textAlign: 'center', color: 'rgba(0, 0, 0, 0.60)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '500', lineHeight: 22, wordWrap: 'break-word'}}>Messages</div>
                <div style={{width: 20, height: 20.62, left: 204, top: 13, position: 'absolute', background: 'rgba(0, 0, 0, 0.60)'}}></div>
            </div>
            <div style={{width: 428, paddingRight: 1, left: 0, top: 0, position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'inline-flex'}}>
                <div style={{flex: '1 1 0', alignSelf: 'stretch', paddingTop: 8, paddingBottom: 13, paddingRight: 14, background: 'rgba(0, 0, 0, 0.10)', justifyContent: 'flex-start', alignItems: 'flex-end', gap: 289, display: 'inline-flex'}}>
                    <div style={{width: 56, alignSelf: 'stretch', paddingTop: 6, paddingLeft: 32, justifyContent: 'flex-end', alignItems: 'center', display: 'inline-flex'}}>
                        <div style={{width: 54, color: 'black', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'}}>9:27</div>
                    </div>
                    <div style={{width: 68, alignSelf: 'stretch', paddingTop: 1, justifyContent: 'center', alignItems: 'flex-start', gap: 2, display: 'inline-flex'}}>
                        <div style={{width: 18, height: 12, position: 'relative', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex'}}>
                            <img style={{width: 17, height: 10.67}} src="https://via.placeholder.com/17x11" />
                        </div>
                        <div style={{width: 21, height: 15, position: 'relative', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex'}}>
                            <img style={{width: 15.33, height: 11}} src="https://via.placeholder.com/15x11" />
                        </div>
                        <div style={{width: 25, height: 12, position: 'relative', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex'}}>
                            <div style={{width: 22, height: 11.33, opacity: 0.35, borderRadius: 2.67, border: '1px black solid'}} />
                            <div style={{width: 18, height: 7.33, background: 'black', borderRadius: 1.33}} />
                            <div style={{width: 1.33, height: 4, opacity: 0.40, background: 'black'}}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{width: 427, height: 214, left: 0, top: 269, position: 'absolute'}}>
                <div style={{width: 220, height: 96, left: 0, top: 36, position: 'absolute'}}>
                    <div style={{width: 119, height: 14, left: 39, top: 23, position: 'absolute', color: 'rgba(0, 0, 0, 0.60)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>Views</div>
                    <div style={{width: 95, height: 22, left: 39, top: 46, position: 'absolute', color: 'black', fontSize: 18, fontFamily: 'Roboto', fontWeight: '500', wordWrap: 'break-word'}}>317,873</div>
                    <div style={{width: 190, height: 74, left: 21, top: 8, position: 'absolute', background: 'rgba(0, 0, 0, 0)', boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.20)', borderRadius: 10, border: '1px rgba(0, 0, 0, 0.15) solid'}} />
                </div>
                <div style={{width: 220, height: 96, left: 0, top: 118, position: 'absolute'}}>
                    <div style={{width: 119, height: 14, left: 39, top: 23, position: 'absolute', color: 'rgba(0, 0, 0, 0.60)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>Subscribers</div>
                    <div style={{width: 95, height: 22, left: 39, top: 46, position: 'absolute', color: 'black', fontSize: 18, fontFamily: 'Roboto', fontWeight: '500', wordWrap: 'break-word'}}>17,873</div>
                    <div style={{width: 190, height: 74, left: 21, top: 8, position: 'absolute', background: 'rgba(0, 0, 0, 0)', boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.20)', borderRadius: 10, border: '1px rgba(0, 0, 0, 0.15) solid'}} />
                </div>
                <div style={{width: 220, height: 96, left: 206, top: 36, position: 'absolute'}}>
                    <div style={{width: 143, height: 14, left: 39, top: 23, position: 'absolute', color: 'rgba(0, 0, 0, 0.60)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>Watch time (hours)</div>
                    <div style={{width: 95, height: 22, left: 39, top: 46, position: 'absolute', color: 'black', fontSize: 18, fontFamily: 'Roboto', fontWeight: '500', wordWrap: 'break-word'}}>391.6K</div>
                    <div style={{width: 190, height: 74, left: 12, top: 8, position: 'absolute', background: 'rgba(0, 0, 0, 0)', boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.20)', borderRadius: 10, border: '1px rgba(0, 0, 0, 0.15) solid'}} />
                </div>
                <div style={{width: 220, height: 96, left: 206, top: 118, position: 'absolute'}}>
                    <div style={{width: 143, height: 14, left: 39, top: 23, position: 'absolute', color: 'rgba(0, 0, 0, 0.60)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>Revenue</div>
                    <div style={{width: 95, height: 22, left: 39, top: 46, position: 'absolute', color: 'black', fontSize: 18, fontFamily: 'Roboto', fontWeight: '500', wordWrap: 'break-word'}}>391.6K</div>
                    <div style={{width: 190, height: 74, left: 12, top: 8, position: 'absolute', background: 'rgba(0, 0, 0, 0)', boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.20)', borderRadius: 10, border: '1px rgba(0, 0, 0, 0.15) solid'}} />
                </div>
                <div style={{paddingTop: 4, paddingBottom: 5, paddingLeft: 3, left: 18, top: 5, position: 'absolute', justifyContent: 'center', alignItems: 'center', gap: 102, display: 'inline-flex'}}>
                    <div style={{width: 165, height: 22, color: 'black', fontSize: 18, fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word'}}>Channel analytics</div>
                    <div style={{width: 119, height: 14, textAlign: 'right', color: 'rgba(0, 0, 0, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>Last one month</div>
                </div>
            </div>
            <div style={{width: 427, height: 157, left: 0, top: 106, position: 'absolute'}}>
                <div style={{width: 120, height: 120, left: 21, top: 20, position: 'absolute', background: '#EFF1F2', borderRadius: 100, border: '1px rgba(0, 0, 0, 0.15) solid'}} />
                <div style={{width: 219, height: 22, left: 161, top: 35, position: 'absolute', color: 'black', fontSize: 20, fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word'}}>Reyanshu Shorts</div>
                <div style={{width: 165, height: 22, left: 161, top: 101, position: 'absolute', color: 'rgba(0, 0, 0, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>@reyanshushorts</div>
                <div style={{width: 119, height: 14, left: 196, top: 73, position: 'absolute', color: 'rgba(0, 0, 0, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>Roll Number</div>
                <div style={{width: 95, height: 22, left: 161, top: 65, position: 'absolute', color: 'black', fontSize: 24, fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word'}}>18</div>
            </div>
            <div style={{width: 426, height: 265, left: 0, top: 494, position: 'absolute'}}>
                <div style={{width: 426, height: 41, paddingTop: 5, paddingBottom: 5, paddingLeft: 18, paddingRight: 19, left: 0, top: 1, position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'inline-flex'}}>
                    <div style={{flex: '1 1 0', alignSelf: 'stretch', paddingTop: 4, paddingBottom: 5, paddingLeft: 3, paddingRight: 147, justifyContent: 'flex-start', alignItems: 'center', display: 'inline-flex'}}>
                        <div style={{width: 239, height: 22, color: 'black', fontSize: 18, fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word'}}>Latest published content</div>
                    </div>
                </div>
                <div style={{width: 384, height: 200, left: 23, top: 46, position: 'absolute', background: 'rgba(217, 217, 217, 0)', borderRadius: 10, border: '1px rgba(0, 0, 0, 0.10) solid'}} />
                <div style={{width: 426, height: 227, left: 0, top: 38, position: 'absolute'}}>
                    <div style={{width: 56, height: 76, left: 54, top: 25, position: 'absolute', background: '#EFF1F2', borderRadius: 5}} />
                    <div style={{width: 257, left: 125, top: 30, position: 'absolute', color: 'black', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>cute baby enjoying in water balloon pop...</div>
                    <div style={{width: 257, left: 125, top: 52, position: 'absolute', color: 'rgba(0, 0, 0, 0.50)', fontSize: 13, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>First 9 hours 54 minutes</div>
                    <div style={{width: 342, height: 57, left: 41, top: 110, position: 'absolute'}}>
                        <div style={{width: 339, height: 0, left: 3, top: 51, position: 'absolute', border: '1px rgba(0, 0, 0, 0.15) solid'}}></div>
                        <div style={{width: 339, height: 0, left: 3, top: 4, position: 'absolute', border: '1px rgba(0, 0, 0, 0.15) solid'}}></div>
                        <div style={{width: 47, height: 18, left: 32, top: 19, position: 'absolute', textAlign: 'center', color: 'rgba(0, 0, 0, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>4.3K</div>
                        <div style={{width: 20, height: 22, left: 12, top: 16, position: 'absolute', background: 'rgba(0, 0, 0, 0.50)'}}></div>
                        <div style={{width: 47, height: 18, left: 167, top: 19, position: 'absolute', textAlign: 'center', color: 'rgba(0, 0, 0, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>96</div>
                        <div style={{width: 20, height: 20, left: 156, top: 15, position: 'absolute', background: 'rgba(0, 0, 0, 0.50)'}}></div>
                        <div style={{width: 47, height: 18, left: 295, top: 19, position: 'absolute', textAlign: 'center', color: 'rgba(0, 0, 0, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>12</div>
                        <div style={{width: 20, height: 20, left: 285, top: 17, position: 'absolute', background: 'rgba(0, 0, 0, 0.50)'}}></div>
                    </div>
                </div>
            </div>
            <div style={{width: 427, height: 64, left: 0, top: 42, position: 'absolute'}}>
                <div style={{width: 428, height: 53.71, left: 0, top: 41.06, position: 'absolute'}} />
                <div style={{width: 428, height: 53.71, left: 0, top: 5, position: 'absolute', background: 'white'}}>
                    <img style={{width: 148, height: 44.89, left: 15, top: 4.76, position: 'absolute'}} src="https://via.placeholder.com/148x45" />
                    <div style={{width: 33.88, height: 32, left: 375, top: 11, position: 'absolute', background: '#1C1B1F'}}></div>
                    <div style={{width: 428, height: 0, left: -0, top: 55.66, position: 'absolute', border: '1px rgba(0, 0, 0, 0.15) solid'}}></div>
                </div>
            </div>
            <div style={{width: 426, height: 224, left: 0, top: 759, position: 'absolute'}}>
                <div style={{width: 386, height: 181, left: 21, top: 11, position: 'absolute', background: '#007EFA', borderRadius: 10, border: '1px #007EFA solid'}} />
                <div style={{width: 360, height: 157, left: 34, top: 24, position: 'absolute', background: 'rgba(255, 255, 255, 0)', borderRadius: 10, border: '2px white solid'}} />
                <div style={{width: 321, height: 22, left: 58, top: 38, position: 'absolute', color: 'white', fontSize: 20, fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word'}}>Watch Premium Content</div>
                <div style={{width: 318, height: 52, left: 58, top: 72, position: 'absolute', color: 'rgba(255, 255, 255, 0.90)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word'}}>Live Classes, Viral Sounds, Tricks and Titles on taking our subscription and we will do live analytics of your channel.</div>
                <div style={{width: 110, height: 32, left: 58, top: 133, position: 'absolute', background: 'white'}} />
                <div style={{width: 110, height: 16, left: 58, top: 141, position: 'absolute', textAlign: 'center', color: '#007EFA', fontSize: 14, fontFamily: 'Roboto', fontWeight: '500', textTransform: 'uppercase', wordWrap: 'break-word'}}>Pay Rs.199</div>
            </div>
        </div>
    </div>
  )
}

export default User
