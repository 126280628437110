import React, { useEffect, useState } from 'react'
import asknaveen from '../assets/logo/asknaveen.jpg'
import addChannel from '../assets/logo/addChannel.svg'
import notification from '../assets/logo/notification.svg'
import search from '../assets/logo/search.svg'
import country from '../assets/logo/country.svg'
import dashboard from '../assets/logo/dashboard.svg'
import video from '../assets/logo/video.svg'
import channel from '../assets/logo/channels.svg'
import community from '../assets/logo/community.png'
import more from '../assets/logo/more.svg'
import Icons from './Icons'
import SideBar from './SideBar'
import { useAppSelector, useAppDispatch } from '../hooks/hooks'
import useTokenUtils from '../utils/tokenUtils'
import { myChannels } from '../features/channel/actions'
import { userDetails } from '../features/user/actions'


const Header:React.FC = () => {
  const {CircleIcon} = Icons()
  const {data:chDetailsData,isLoading:isChannelDetailsLoading} = useAppSelector((state:any)=>state.channel.channelDetails)
  const [channelDetailsData] = chDetailsData?chDetailsData:''
  const dispatch = useAppDispatch()
  const {getTokenFromLocalStorage} = useTokenUtils()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    dispatch(myChannels(getTokenFromLocalStorage()))
    dispatch(userDetails(getTokenFromLocalStorage()))
  }, []);

  return (
    <div>
      <div className='m-0 pt-2 mb-2' style={{ color: 'black', fontSize: '20px'}}> {/* color  */}
        <div className='d-flex justify-content-between align-items-center justify-content-center overflow-auto'>
            <img style={{width: 148, height: 44.89 }} src={asknaveen} />
            <div className='d-flex align-items-center justify-content-center' style={{width:'50%', maxWidth:'250px'}}> {/* color  */}
                <img style={{width: 108, height: 25.89 }} src={country} />
                <img style={{width: 108, height: 25.89 }} src={notification} />
                <img style={{width: 108, height: 25.89 }} src={search} />
                <div className='text-center me-1'>
                    {/* <img style={{ height: 30.89, width:70}} src={addChannel} />
                    <div style={{fontSize:'10px'}}>Add Channel</div> */}
                    <div onClick={toggleSidebar}><CircleIcon imageURL={channelDetailsData && channelDetailsData.channelImage}/></div>
                </div>
            </div>
        </div>
        <div className='mt-2' style={{width: '100%', height: 0, left: -0, top: 55.66, border: '1px rgba(0, 0, 0, 0.15) solid'}}></div>
      </div>
      <SideBar
      isSidebarOpen={isSidebarOpen}
      toggleSidebar={toggleSidebar}
      />
    </div>
  )
}

export default Header
