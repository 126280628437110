import React, { useEffect } from 'react'
import Navbar from '../../components/Navbar'
import Header from '../../components/Header'
import useAuthUtils from '../../utils/authUtils';
import ScrollContainer from '../../components/ScrollContainer';

export interface IDashboard {
    PAGE?: React.ReactNode;
}

const Main:React.FC <IDashboard> = ({PAGE}) => {
  // const {checkAndDispatchIsILogin} = useAuthUtils()
  // useEffect(() => {
  //   checkAndDispatchIsILogin()
  // }, []);
  return (
    <div >
      {/* <ScrollContainer type=''>

      </ScrollContainer> */}
      <div className='' style={{height:'100vh'}}> {/* color  */}
        {/* <div className='container p-0' style={{height:'100vh',position:'relative'}}> color  */}
        <div className='p-0' style={{height:'100vh',position:'relative'}}> {/* color  */}
            {/* top header  */}
            <Header/>
            {/* <div className='d-flex align-items-center justify-content-center' style={{height:'74%'}}>
                {PAGE && PAGE}
            </div> */}
            <div style={{height:'74%'}}>
                {PAGE && PAGE}
            </div>
            {/* bottom navbar  */}
            <Navbar/>
        </div>
    </div>
    </div>
  )
}

export default Main
