import React, { useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '../hooks/hooks'
import { handleFilters as handleVideosFilters } from '../features/video/actions';
import { handleFilters as handleChannelsFilters } from '../features/channel/actions';

interface IMiddleware {
    children: React.ReactNode;
    type: string;
}

const ScrollContainer: React.FC <IMiddleware> = ({children,type}) => {
    const dispatch = useAppDispatch()
    const {data:channelVideosData,isLoading:isChannelVidLoading,query:cvQuery} = useAppSelector((state:any)=>state.video.channelVideos)
    // console.log(cvQuery.pageLimit)

  const containerRef = useRef<HTMLDivElement>(null);

  const yourCustomScrollHandler = (containerRef: React.RefObject<HTMLDivElement>) => {
    if (containerRef.current) {
      const container = containerRef.current;
      const isScrolledToBottom = container.scrollTop + container.clientHeight === container.scrollHeight;
      if (isScrolledToBottom) {
        console.log('Scrolled to bottom');
        // dispatch(handlePageLimit(50))
        if(type=='videos'){
          dispatch(handleVideosFilters({type:'limit',value:50}))
        }
        if(type=='channels'){
          console.log('channels')
          dispatch(handleChannelsFilters({type:'page',value:1}))
        }
        if(type=='channelVideos'){
          console.log('channel Videos')
        }
        
      }
    }
  };



  useEffect(() => {
    const container = containerRef.current;
    const scrollHandler = () => yourCustomScrollHandler(containerRef);
    if (container) {
      container.addEventListener('scroll', scrollHandler);
      return () => {
        container.removeEventListener('scroll', scrollHandler);
      };
    }
  }, [containerRef]);

  return (
    <div ref={containerRef} style={{ height: '100vh', overflowY: 'auto' }}>
      <React.Fragment>{children}</React.Fragment>
    </div>
  );
};

export default ScrollContainer;
