import * as Yup from 'yup';

export const sendOtpSchema = Yup.object().shape({
    email:Yup.string().email('please enter a valid email').required('Please Enter Valid Email Id'),
})

export const verifyOtpSchema = Yup.object().shape({
    email:Yup.string().email('please enter a valid email').required('Please enter your email'),
    otp:Yup.number().test("min-length", "Please enter a valid otp", (value) => {
        const stringValue = String(value);
        return stringValue.length === 6;
    })
})