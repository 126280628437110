import axios,{AxiosError} from 'axios';
import Toast from '../components/Toast';
import { handleShowToast } from '../features/Toast/actions';
import { isILogin_URL, sendOtp_URL, verifyOtp_URL } from '../utils/url_helper';



export class authentication {

    sendOtp = async (email:string) => {
        try {
            // const url = `http://localhost:9721/nodeapi/user/generateotp`
            const url = sendOtp_URL
            const sendOtpRes = await axios(url, {
                method:'post',
                data:{email:email}
            })
            return sendOtpRes
        } catch (error) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }
    verifyOtp = async(email:string,otp:number) => {
        try {
            // const url = 'http://localhost:9721/nodeapi/user/login'
            const url = verifyOtp_URL
            const loginUser = await axios(url, {
                method:'post',
                data:{email:email, otp:otp}
            })
            const {status,message,token} = loginUser?.data
            if(status=='success' && token){
                localStorage.setItem('token', JSON.stringify(token));
                document.cookie="username=John Doe";
            }
            return loginUser
        } catch (error) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }
    
    getIsILogin  = async(token:string) => {
        try {
            // const url = `http://localhost:9721/nodeapi/user/validation`
            const url = isILogin_URL
            const validationAdminRes = await axios(url, {
                headers: {'Authorization': `Token ${token}`},
                method:'post'
            })
            return validationAdminRes
    
        } catch (error:any) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }
}