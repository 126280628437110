import { useAppDispatch } from '../hooks/hooks';
import { getIsILogin } from '../features/auth/actions';
import useTokenUtils from '../utils/tokenUtils'

const useAuthUtils = () => {
    const dispatch = useAppDispatch();
    const {setTokenToLocalStorage, getTokenFromLocalStorage} = useTokenUtils()
    const checkAndDispatchIsILogin = () => {
      dispatch(getIsILogin(getTokenFromLocalStorage()));
    };
    return { checkAndDispatchIsILogin };
};

export default useAuthUtils;
  