import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { channelVideos, communityVideos, videos } from './actions';

export const channelVideosSlice = createSlice({
  name:'channelVideos',
  initialState:{
      data:null,
      isLoading:false,
      isError:false,
      errorMessage:null,
      query:{
        ytChannelId:'',
        page:0,
        pageLimit:50,
        shortBy:'dailyViews'
      }
  },
  extraReducers:(builder) => {
    builder.addCase(channelVideos.pending, (state, action)=>{
      state.isLoading = true;
    })
    builder.addCase(channelVideos.fulfilled, (state, action)=>{
        state.isLoading = false;
        state.data = action.payload;
    })
    builder.addCase(channelVideos.rejected, (state:any, action:any)=>{
        if(action.payload.isValidationError){
          state.isLoading = false;
          state.isValidationError = true
          state.validationMessage = action.payload.validationMessage
        }else{
          console.log("error", action.payload, action.error.message || 'An error occurred');
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload
        }
    })
  },
  reducers:{
    handleYtChannelID:(state,action)=>{
        state.query.ytChannelId = action.payload
    },
    handlePageLimit:(state,action)=>{
      state.query.pageLimit += action.payload
    },
    handleSelect:(state,action)=>{
      state.query.shortBy = action.payload
    }
  }
})

export const communityVideosSlice = createSlice({
  name:'communityVideos',
  initialState:{
      data:null,
      isLoading:false,
      isError:false,
      errorMessage:null,
      body:{
        userVideoStatus:'active',
      }
  },
  extraReducers:(builder) => {
    builder.addCase(communityVideos.pending, (state, action)=>{
      state.isLoading = true;
    })
    builder.addCase(communityVideos.fulfilled, (state, action)=>{
        state.isLoading = false;
        state.data = action.payload;
    })
    builder.addCase(communityVideos.rejected, (state:any, action:any)=>{
        if(action.payload.isValidationError){
          state.isLoading = false;
          state.isValidationError = true
          state.validationMessage = action.payload.validationMessage
        }else{
          console.log("error", action.payload, action.error.message || 'An error occurred');
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload
        }
    })
  },
  reducers:{
    
  }
})

export const videosSlice = createSlice({
  name:'videos',
  initialState:{
      data:null,
      isLoading:false,
      isError:false,
      errorMessage:null,
      params:{
        page:0,
        pageLimit:50,
        videoType:'Viral',
        country:"IN",
        categoryId:'',
        isOfficialMusic:'',
        sortBy:'',
        search:''
      },
  },
  extraReducers:(builder) => {
    builder.addCase(videos.pending, (state, action)=>{
      state.isLoading = true;
    })
    builder.addCase(videos.fulfilled, (state, action)=>{
        state.isLoading = false;
        state.data = action.payload;
    })
    builder.addCase(videos.rejected, (state:any, action:any)=>{
        if(action.payload.isValidationError){
          state.isLoading = false;
          state.isValidationError = true
          state.validationMessage = action.payload.validationMessage
        }else{
          console.log("error", action.payload, action.error.message || 'An error occurred');
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload
        }
    })
  },
  reducers:{
    handleFilters:(state,action)=>{
      const {type,value} = action.payload
      switch (type) {
        case 'country':
          state.params.country = value;
          break;
        case 'category':
          state.params.categoryId = value;
          break;
        case 'sortBy':
          state.params.sortBy = value;
          break;
        case 'limit':
          state.params.pageLimit += value
          break;
        case 'videoType':
          state.params.videoType = value
          break;
        default:
          break;
      }
    } 
  }
})