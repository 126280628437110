import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { settingValue } from './actions';

export const settingValueSlice = createSlice({
  name:'settingValue',
  initialState:{
      data:null,
      isLoading:false,
      isError:false,
      errorMessage:null,
  },
  extraReducers:(builder) => {
    builder.addCase(settingValue.pending, (state, action)=>{
      state.isLoading = true;
    })
    builder.addCase(settingValue.fulfilled, (state, action)=>{
        state.isLoading = false;
        state.data = action.payload;
    })
    builder.addCase(settingValue.rejected, (state:any, action:any)=>{
        if(action.payload.isValidationError){
          state.isLoading = false;
          state.isValidationError = true
          state.validationMessage = action.payload.validationMessage
        }else{
          console.log("error", action.payload, action.error.message || 'An error occurred');
          state.isLoading = false;
          state.isError = true;
          state.errorMessage = action.payload
        }
    })
  },
  reducers:{
  }
})