import {createAsyncThunk} from '@reduxjs/toolkit'
import { channel } from "../../api/channel"
import { channelDetailsSlice, channelsSlice } from './slices'
const channelModel = new channel()

export const myChannels = createAsyncThunk('myChannels', async(token:any,{ rejectWithValue })=>{
    try {
        const myChannelsRes:any = await channelModel.myChannels(token)
        return myChannelsRes.data.data
    } catch (error:any) {
        if(error.errors){
            return rejectWithValue({isValidationError:true, validationMessage:error.errors[0] || 'An error occurred during validation'});
        }
        return rejectWithValue(error.message)
    }
})
export const channelDetails = createAsyncThunk('channelDetails', async(id:any,{ rejectWithValue })=>{
    try {
        const channelDetailsRes:any = await channelModel.channelDetails(id)
        return channelDetailsRes.data.data
    } catch (error:any) {
        if(error.errors){
            return rejectWithValue({isValidationError:true, validationMessage:error.errors[0] || 'An error occurred during validation'});
        }
        return rejectWithValue(error.message)
    }
})
export const channelHistory = createAsyncThunk('channelHistory', async(ytChannelID:string|undefined,{ rejectWithValue })=>{
    try {
        const channelHistoryRes:any = await channelModel.channelHistory(ytChannelID)
        return channelHistoryRes.data.result
    } catch (error:any) {
        if(error.errors){
            return rejectWithValue({isValidationError:true, validationMessage:error.errors[0] || 'An error occurred during validation'});
        }
        return rejectWithValue(error.message)
    }
})
export const channels = createAsyncThunk('channels', async({params,token}:{params:any,token:any},{ rejectWithValue })=>{
    try {
        if(params.isFavourite==='Y'){
            const channelRes:any = await channelModel.favouriteChannels(params,token)
            return channelRes.data.data
        }else{
            const channelsRes:any = await channelModel.channels(params)
            return channelsRes.data.data
        }
    } catch (error:any) {
        if(error.errors){
            return rejectWithValue({isValidationError:true, validationMessage:error.errors[0] || 'An error occurred during validation'});
        }
        return rejectWithValue(error.message)
    }
})
export const favChannelsIdsArr = createAsyncThunk('favChannelsIdsArr', async(token:any,{ rejectWithValue })=>{
    try {
        const channelsRes:any = await channelModel.favChannelsIdsArr(token)
        return channelsRes
    } catch (error:any) {
        if(error.errors){
            return rejectWithValue({isValidationError:true, validationMessage:error.errors[0] || 'An error occurred during validation'});
        }
        return rejectWithValue(error.message)
    }
})

export const {handleFilters} = channelsSlice.actions
