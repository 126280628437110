import React from 'react'
import { useAppSelector, useAppDispatch } from '../hooks/hooks'
import { handleSelect, videos } from '../features/video/actions';
import { handleFilters as handleVideosFilters } from '../features/video/actions';
import { handleFilters as handleChannelsFilters } from '../features/channel/actions';

const Select:React.FC <any> = ({selectData,type,filterType,selected}:{selectData:any,type:string,filterType:string,selected:string}) => {
  const dispatch = useAppDispatch()
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    switch(type){
      case 'channelvideos':
        dispatch(handleSelect(selectedValue))
        break;
      case 'videos':
        dispatch(handleVideosFilters({type:filterType,value:selectedValue}))
        break;
      case 'channels':
        dispatch(handleChannelsFilters({type:filterType,value:selectedValue}))
        break;
      default:
        break;
    }
  };


  return (
    <select 
    className='p-1 ps-1 pe-1' 
    style={{
    margin:'0 10px',
    width:'auto', 
    height:'100%', 
    background:'#F2F4F5', 
    borderRadius:5,
    outline:'none',
    border:'none'
    }}
    onChange={handleChange}
    >
    {selected && <option value="" disabled>{selected}</option>}
    {
    selectData &&
    selectData.map((data:any,index:number)=>{
    return(
    <option value={data.value}>{data.label}</option>
    )
    })
    }
    </select>
  )
}

export default Select
