import axios,{AxiosError} from 'axios';
import { settingValues_URL } from '../utils/url_helper';

export class Setting{

    countries = async (settingKey:string) => {
        try {
            const requestOptions = {
                method: 'get',
                // url: `http://localhost:9721/nodeapi/setting/key/${settingKey}`,
                url: `${settingValues_URL}/${settingKey}`,
            };
            const settingValueRes = await axios(requestOptions)
            const country = settingValueRes.data.data
            const countryJSON = JSON.parse(country.settingValue)
            const countryArr = []
            for (let [value, label] of Object.entries(countryJSON)) {
                countryArr.push({label,value})
            }
            return {countries:countryArr };
        } catch (error:any) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }

    videoCategory = async (settingKey:string) => {
        try {
            const requestOptions = {
                method: 'get',
                // url: `http://localhost:9721/nodeapi/setting/key/${settingKey}`,
                url: `${settingValues_URL}/${settingKey}`,
            };
            const settingValueRes = await axios(requestOptions)
            const videoCategory = settingValueRes.data.data
            const videoCategoryJSON = JSON.parse(videoCategory.settingValue)
            const videoCategoryArr = []
            for (let [value, label] of Object.entries(videoCategoryJSON)) {
                videoCategoryArr.push({label, value})
            }
            return {categories:videoCategoryArr}
            // return {videoCategories:videoCategoryArr}
        } catch (error:any) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }

    videoType = async (settingKey:string) => {
        try {
            const requestOptions = {
                method: 'get',
                // url: `http://localhost:9721/nodeapi/setting/key/${settingKey}`,
                url: `${settingValues_URL}/${settingKey}`,
            };
            const settingValueRes = await axios(requestOptions)
            const videoType = settingValueRes.data.data.settingValue.split(',')
            const videoTypeArr= videoType.map((value:any)=>{
                return {label:value, value:value}
            })
            return {videoType:videoTypeArr}
        } catch (error:any) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }

    proBenefits = async (settingKey:string) => {
        try {
            const requestOptions = {
                method: 'get',
                // url: `http://localhost:9721/nodeapi/setting/key/${settingKey}`,
                url: `${settingValues_URL}/${settingKey}`,
            };
            const settingValueRes = await axios(requestOptions)
            let proBenefitsJsonRes
            if(settingValueRes.data.status==='success'){
                proBenefitsJsonRes = JSON.parse(settingValueRes.data.data.settingValue)
            }
            return proBenefitsJsonRes.data
        } catch (error:any) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }
}