import React, { useEffect } from 'react'
import Back from '../components/Back'
import PerformanceTable from '../components/tables/PerformanceTable'
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hooks/hooks'
import { channelHistory } from '../features/channel/actions';


const Performance:React.FC = () => {
  const { ytChannelID } = useParams();
  const dispatch = useAppDispatch()

  useEffect(()=>{
    if(ytChannelID){
      dispatch(channelHistory(ytChannelID))
    }
  },[])
  return (
      <div className='' style={{height:'100vh'}}> {/* color  */}
        <div className='p-0' style={{height:'100vh',position:'relative'}}> {/* color  */}
            <div style={{height:'74%'}}>
                <div className='container' style={{maxWidth:'800px'}}>
                    <Back/>
                    <PerformanceTable/>
                </div>  
            </div>
        </div>
      </div>
  )
}

export default Performance
