import React, { useEffect } from 'react'
import Icons from '../components/Icons'



const Analytic:React.FC <any> = ({title,data,toolTip}:{title:string,data:string,toolTip:string}) => {
    const {HelpIcon} = Icons()

    // var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    // var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    //   // return new bootstrap.Tooltip(tooltipTriggerEl)
    //   return new (window as any).bootstrap.Tooltip(tooltipTriggerEl);
    // })

  useEffect(()=>{
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      // return new bootstrap.Tooltip(tooltipTriggerEl)
      return new (window as any).bootstrap.Tooltip(tooltipTriggerEl);
    })
  },[])

  return (
        <div className='col-6 col-sm-4 col-md-3 p-2'>
        <div className='p-2' style={{background: 'rgba(0, 0, 0, 0)',boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.20)', borderRadius: 10, border: '1px rgba(0, 0, 0, 0.15) solid'}}>
            <div className='d-flex' style={{color: 'rgba(0, 0, 0, 0.60)',fontSize:14,fontFamily:'Roboto',fontWeight:400}}>
              {title}
              <div className='ms-1' data-bs-toggle="tooltip" data-bs-placement="bottom" title={toolTip}>
                <HelpIcon color='gray'/>
              </div>
            </div>
            <div style={{color: 'black',fontSize:15,fontWeight:500}}>{data}</div>
        </div>
        </div>


  )
}

export default Analytic
