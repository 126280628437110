import React from 'react'
import Icons from './Icons'


const Video:React.FC <any> = ({title,name,views,dailyViews,likes,date,imageURL,duration,equalizerIcon,trendingUpIcon}:{title:string,name:string,views:string,dailyViews:string,likes:string,date:string,imageURL:string,duration:string,equalizerIcon:any,trendingUpIcon:any}) => {
    const {PlayIcon,EqualizerIcon,ThumbsUpIcon,TrendingUpIcon} = Icons()
  return (
    <div className='col-12 col-sm-6 col-md-6 mb-2 p-0 d-flex'>
        <div className='d-flex ps-2 pe-2 justify-content-md-center'>
            {/* <div style={{ position: 'relative', width: 130, height: 90, transformOrigin: '0 0', background: '#EFF1F2', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div><PlayIcon/></div>
                <div className='me-1 mb-1' style={{ position: 'absolute', bottom: 0, right: 0, backgroundColor:'black', borderRadius:4,color:'white',paddingLeft:4,paddingRight:4,fontSize:12,fontFamily:'Roboto'}}>00:18</div>
            </div> */}
            <div style={{ position: 'relative', width: 130, height: 90, transformOrigin: '0 0', background: '#EFF1F2', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img style={{ position: 'relative', width: 130, height: 90, transformOrigin: '0 0', background: '#EFF1F2', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }} src={`https://img.youtube.com/vi/${imageURL}/maxresdefault.jpg`}/>
                <div className='me-1 mb-1' style={{ position: 'absolute',borderRadius:4,color:'white',paddingLeft:4,paddingRight:4,fontSize:12,fontFamily:'Roboto'}}><PlayIcon/></div>
                <div className='me-1 mb-1' style={{ position: 'absolute', bottom: 0, right: 0, backgroundColor:'black', borderRadius:4,color:'white',paddingLeft:4,paddingRight:4,fontSize:12,fontFamily:'Roboto'}}>{duration}</div>
            </div>
            <div className='ms-2 d-flex align-items-center' style={{color: 'rgba(0, 0, 0, 0.50)',justifyContent:'center'}}>
                <div>
                    <div style={{color:'black', fontSize:15, fontWeight:500}}>{title} {trendingUpIcon}</div>
                    <div style={{fontSize:13,fontFamily:'Roboto',fontWeight:400}}>{name}</div>
                    <div className='d-flex' style={{fontSize:13,fontFamily:'Roboto',fontWeight:400}}>
                        <div className='d-flex me-1'>{equalizerIcon}</div>
                        <div className='d-flex'>
                            <div>{views}</div>
                            <div className='ms-1 me-3' style={{color:'green'}}>{dailyViews}</div>
                        </div>
                        <ThumbsUpIcon/>
                        <div className='ms-1'>{likes}</div>
                    </div>
                    <div style={{fontSize:13,fontFamily:'Roboto',fontWeight:400}}>{date}</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Video
