import axios,{AxiosError} from 'axios';
import { channelDetails_URL, channelHistory_URL, channels_URL, favouriteChannelsIdsArr_URL, favouriteChannels_URL, myChannels_URL } from '../utils/url_helper';

export class channel{

    myChannels = async (token:string) => {
        try {
            // const url = `http://localhost:9721/nodeapi/channel/mychannel`
            const url = myChannels_URL
            const myChannelsRes = await axios(url, {
                headers: {'Authorization': `Token ${token}`},
                method:'post'
            })
            return myChannelsRes
        } catch (error:any) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }

    channelDetails = async (id:any) => {
        // console.log(id)
        try {
            // const url = `http://localhost:9721/nodeapi/channel/101?dashboard=true`
            // const url = `http://localhost:9721/nodeapi/channel/${id}?dashboard=true`
            const url = `${channelDetails_URL}/${id}?dashboard=true`
            const channelDetailsRes = await axios.get(url)
            return channelDetailsRes
        } catch (error:any) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }

    channelHistory = async (ytChannelID?:string) => {
        try {
            // const url = `http://localhost:9721/nodeapi/channel/history/${ytChannelID}`
            const url = `${channelHistory_URL}/${ytChannelID}`
            const channelHistoryRes = await axios.get(url)
            return channelHistoryRes
        } catch (error:any) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }

    channels = async (params:any) => {
        try {
            const paramsString = new URLSearchParams(params).toString();
            // const url = `http://localhost:9721/nodeapi/channel/?${paramsString}`
            const url = `${channels_URL}/?${paramsString}`
            const channelsRes = await axios.get(url)
            return channelsRes
        } catch (error:any) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }

    favouriteChannels = async (params:any,token:any) => {
        try {
            const headers = {"Authorization": `Token ${token}`};
            const paramsString = new URLSearchParams(params);
            // const url = "http://localhost:9721/nodeapi/channel/";
            const url = favouriteChannels_URL;
            const channelRes = await axios.get(url, {headers,params})
            return channelRes
        } catch (error:any) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }

    favChannelsIdsArr = async (token:string) => {
        try {
            const config = {
                headers: {
                    // 'Authorization': 'Token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJmaW5kbmF2ZWVuam9zaGlAZ21haWwuY29tIiwidXNlclR5cGUiOiJBIiwibmFtZSI6Im5hdmVlbiIsImlhdCI6MTY4ODM5Mzg3N30.HbQ2rAZkX8cwYt6qmmK1HnhrcJS4pLzbxlCDSWANVco'
                    'Authorization': `Token ${token}`
                }
            };
            // const url = "http://localhost:9721/nodeapi/channel/favourite"
            const url = favouriteChannelsIdsArr_URL
            const channelRes = await axios.post(url, {}, config)
            const [favIds] = channelRes.data.result
            let favIdsArr
            if(favIds){
                favIdsArr = favIds.favChannel.split(',')
            }
            const favIdsArrInt = favIdsArr.map((value:string)=>{
                return parseInt(value)
            })
            return favIdsArrInt
            
        } catch (error:any) {
            if(axios.isAxiosError(error)){
                if (error.response) {
                    console.log(error.response.status)
                } else if (error.request) {
                    throw new Error('Network Error...! Please check your internet connection.');
                } else {
                    throw new Error('Error setting up the request');
                }
            }
            console.log(error)
        }
    }

    
}