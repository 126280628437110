export const sortByItems = [
    {label:'Latest',value:'addDateTime'},
    {label:'Publish Date',value:'videoPublishDateTime'},
    {label:'Popular',value:'views'},
    {label:'name',value:'videoTitle'},
    {label:'Last Day Views',value:'dailyViews'},
]
export const channelTabItems = [
    {label:'Verified', value:'verified'},
    {label:'All', value:'all'},
    {label:'Favourite', value:'favourite'},
]
export const videoTypeItems = [
    {label:'Any', value:''},
    {label:'Shorts', value:'shorts'},
    {label:'Long', value:'long'},
]